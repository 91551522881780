// import React, { useState } from 'react';

// const LoginPage = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [loggedIn, setLoggedIn] = useState(false);

//   const handleLogin = () => {
//     // You can implement your authentication logic here
//     // For simplicity, let's just check if both fields are filled
//     if (username && password) {
//       setLoggedIn(true);
//       // You may also want to redirect the user to another page upon successful login
//     } else {
//       alert('Please enter both username and password');
//     }
//   };

//   return (
//     <section id="formPages" className="formPages">
//     <div className="col-md-12 pad text-center">
//     <div className="col-md-12 pad text-center" >
//     <div className="form-section"style={{border:"1px solid #ccc",backgroundColor:"#fff"}} >
//       <h2>Login Page</h2>
//       {loggedIn ? (
//         <div>
//           <p>Welcome, {username}!</p>
//           {/* Add a logout button or redirect the user to another page */}
//         </div>
//       ) : (
//         <form>
//           <label>
           
//             <input placeholder="Email" className="name-input formClass" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
//           </label>
//           <br /> <br />
//           <label>
          
//             <input placeholder="Password" className="name-input formClass" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
//           </label>
//           <br /> <br />
//           <button className="button schedulebtn " type="button" onClick={handleLogin}>
//             Login
//           </button>
//           <br />
//           <br />
//         </form>
//       )}
//       </div>
//       </div>
//     </div>
//     </section>
//   );
// };

// export default LoginPage;


import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const LoginPage = () => {
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory();
  const handleLogin = async () => {
    try {
      // Make a POST request to the login API
      const response = await fetch('https://backendgoldenjubile.jaipuriaedu.in/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem('loginCred', JSON.stringify(responseData.token));
        setLoggedIn(true);
        history.push('/admin-home'); // Redirect to admin-home
      } else {
        alert('Authentication failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <section id="formPages" className="formPages">
      <div className="col-md-12 pad text-center login-count">
        <div className="col-md-12 pad text-center">
          <div className="form-section" style={{ border: "1px solid #ccc", backgroundColor: "#fff" }} >
            <h2>Login Page</h2>
            {loggedIn ? (
              <div>
                <p>Welcome, {}!</p>
                {/* Add a logout button or redirect the user to another page */}
              </div>
            ) : (
              <form>
                <label>
                  <input placeholder="Email" className="name-input formClass" type="text" value={email} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <br /> <br />
                <label>
                  <input placeholder="Password" className="name-input formClass" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br /> <br />
                <button className="button schedulebtn" type="button" onClick={handleLogin}>
                  Login
                </button>
                <br />
                <br />
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
