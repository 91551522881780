
//import React, { useState } from 'react';
import React from 'react';
import { Banner } from "../components/banner";
import { GloriousYears } from "../components/gloriousyears";
import { YearlongCelebrations } from "../components/yearlongcelebrations";
import { Invitation } from "../components/Invitation";
import { KeyHighlights } from "../components/keyhighlights";
import { OurJourney } from "../components/ourJourney";
import { Schedule } from "../components/schedule";
import { InvitingAlumni } from "../components/invitingalumni";
import { ChiefGuest } from "../components/ChiefGuest";




function Home(props){



    return ( <>

    <Banner  />
      <GloriousYears  />
      <ChiefGuest /> 
      <Invitation />
      <KeyHighlights  />
      <OurJourney />
      <Schedule  /> 
      {/* <InvitingAlumni  />  */}
      <YearlongCelebrations />

    </>
   );

}

export default Home;