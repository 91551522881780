import React, { useEffect, useRef, useState } from 'react'
// import Sidebar from './Sidebar'

import axios from 'axios';
// import { API } from "./../constant"

import * as Constants from "./../../constants/Constants";


//icons
// import { IoMdClose } from "react-icons/io";
// import { FaEdit } from "react-icons/fa";
// import { MdDelete } from "react-icons/md";
// import ErrorPage from './ErrorPage';

const Retention = () => {
const tableRef = useRef(null);

const [showModal, setShowModal] = useState(false)
const [UpdateTable, setUpdateTable] = useState(false)


//input fields data start
const [schoolLocation, setSchoolLocation] = useState("")
const [totalStrength, setSetTotalStrength] = useState("")
const [totalStrengthSession, setSetTotalStrengthSession] = useState("")
const [newAdmission, setNewAdmission] = useState("")
const [newAdmissionSession, setNewAdmissionSession] = useState("")
// const [idealStrengthTillDate, setIdealStrengthTillDate] = useState("")
const [totalDropouts, setTotalDropouts] = useState("")
// const [newStrengthTillDate, setNewStrengthTillDate] = useState("")
// const [retentionPercent, setRetentionPercent] = useState("")
//input fields data end

// edit fields start
const [editschoolLocation, setEditSchoolLocation] = useState("")
const [edittotalStrength, setEditTotalStrength] = useState(Number)
const [edittotalStrengthSession, setEditTotalStrengthSession] = useState("")
const [editnewAdmission, setEditNewAdmission] = useState(Number)
const [editnewAdmissionSession, setEditNewAdmissionSession] = useState("")
const [editidealStrengthTillDate, setEditIdealStrengthTillDate] = useState("")
const [edittotalDropouts, setEditTotalDropouts] = useState("")
const [editnewStrengthTillDate, setEditNewStrengthTillDate] = useState("")
const [editretentionPercent, setEditRetentionPercent] = useState("")
// edit fields end

// fetch school location data start
const [schoolLocationData, setSchoolLocationData] = useState([])
useEffect(() => {
axios.get(`${Constants.API_PATH}/school-location`)
.then((res) => {
console.log(res.data.data)
setSchoolLocationData(res.data.data)
}).catch((error) => {
console.log(error)
})
}, [UpdateTable])
// fetch school location data end

// fetch session start
const [sessionData, setSessionData] = useState([])
useEffect(() => {
axios.get(`${Constants.API_PATH}/session`)
.then((res) => {
console.log(res)
setSessionData(res.data.data)
})
.catch((error) => {
console.log(error)
})
}, [UpdateTable])
// fetch session end

// calculate modal start
let inttotalStrength = parseInt(totalStrength)
let intnewAdmission = parseInt(newAdmission)
let inttotalDropouts= parseInt(totalDropouts)
let idealStrengthTillDate = inttotalStrength + intnewAdmission
let newStrengthTillDate = idealStrengthTillDate - inttotalDropouts
let retentionPercent = (inttotalDropouts / idealStrengthTillDate) * 100
console.log(idealStrengthTillDate)
if(isNaN(idealStrengthTillDate)){
idealStrengthTillDate = 0

}else{
idealStrengthTillDate = idealStrengthTillDate

}
console.log(newStrengthTillDate)
if(isNaN(newStrengthTillDate)){
newStrengthTillDate = 0

}else{
newStrengthTillDate = newStrengthTillDate
}

if(isNaN(retentionPercent)){
retentionPercent = 0
}else{
retentionPercent = parseInt(retentionPercent)
}

// calculate modal end

// edit calculate modal start
let caleditidealStrengthTillDate = parseInt(Number(edittotalStrength) + Number(editnewAdmission))
let caleditnewStrengthTillDate = parseInt(caleditidealStrengthTillDate - edittotalDropouts)
let caleditretentionPercent = parseInt((edittotalDropouts / caleditidealStrengthTillDate) * 100)
// edit calculate modal end

function clearFields() {
setSchoolLocation("")
setSetTotalStrength("")
setSetTotalStrengthSession("")
setNewAdmission("")
setNewAdmissionSession("")
// setIdealStrengthTillDate("")
setTotalDropouts("")
// setNewStrengthTillDate("")
// setRetentionPercent("")

setEditSchoolLocation("")
setEditTotalStrength("")
setEditTotalStrengthSession("")
setEditNewAdmission("")
setEditNewAdmissionSession("")
setEditIdealStrengthTillDate("")
setEditTotalDropouts("")
setEditNewStrengthTillDate("")
setEditRetentionPercent("")

}

// operation on retention modal start
const [tableData, setTableData] = useState([])
const [RetentionById, setRetentionById] = useState("")
const [editRetentionModal, setEditRetentionModal] = useState(false)
const [deletePopUp, SetDeletePopUp] = useState(false)
const [CapacityUtilizationToDeleteId, setCapacityUtilizationToDeleteId] = useState("")
const [showPage, setshowPage] = useState(false)

const submitRateOfConversion = async () => {
console.log("going to submit")
const data={
shool_location: schoolLocation,
total_strength: totalStrength,
total_strength_session: totalStrengthSession,
new_admission: newAdmission,
new_admission_session: newAdmissionSession,
total_dropouts: totalDropouts,
ideal_strength_till_date: idealStrengthTillDate,
net_strength_till_date: newStrengthTillDate,
retention_percent: retentionPercent
}
// console.log(data)
await axios.post(`${Constants.API_PATH}/retention`,data)
console.log("going to submited")

.then((res) => {
// console.log(res)
clearFields()
setUpdateTable(!UpdateTable)
setShowModal(!showModal)
alert(res.data.message)
})
.catch((error) => {
console.log(error)
alert(error.response.data.message)

})
}

useEffect(() => {
axios.get(`${Constants.API_PATH}/retention`, {
headers: {
"Content-Type": "application/json",
"token": localStorage.getItem("token")
}
})
.then((res) => {
console.log(res)
if (res.data.message === "OOPS!! You do not have access to this route." || res.data.message === "User is not valid!!" || res.data.message === "User not authorized to access this page!!") {
// return <ErrorPage />
} else {
if (res.data.data !== undefined) {
setshowPage(true)
if (res.data.data.length > 0) {
setTableData(res.data.data)
}
else {
setTableData([])
}
}

}
})
.catch((error) => {
console.log(error)
})
}, [UpdateTable])

const HandleEditRetentionById = async (id) => {
setRetentionById(id)
await axios.get(`${Constants.API_PATH}/retention/${id}`)
.then((res) => {
setEditRetentionModal(!editRetentionModal)
// setEditRetentionModal(!editRetentionModal)
setEditSchoolLocation(res.data.data.shool_location)
setEditTotalStrength(res.data.data.total_strength)
setEditTotalStrengthSession(res.data.data.total_strength_session)
setEditNewAdmission(res.data.data.new_admission)
setEditNewAdmissionSession(res.data.data.new_admission_session)
setEditIdealStrengthTillDate(res.data.data.ideal_strength_till_date)
setEditTotalDropouts(res.data.data.total_dropouts)
setEditNewStrengthTillDate(res.data.data.net_strength_till_date)
setEditRetentionPercent(res.data.data.retention_percent)


})
.catch((error) => {
console.log(error)
})
}

const HandleEditRetention = async (id) => {
await axios.patch(`${Constants.API_PATH}/retention/${id}`, {
shool_location: editschoolLocation,
total_strength: edittotalStrength,
total_strength_session: edittotalStrengthSession,
new_admission: editnewAdmission,
new_admission_session: editnewAdmissionSession,
ideal_strength_till_date: caleditidealStrengthTillDate,
total_dropouts: edittotalDropouts,
net_strength_till_date: caleditnewStrengthTillDate,
retention_percent: caleditretentionPercent
}).then((res) => {
// console.log(res.data)
alert(res.data.message)
setUpdateTable(!UpdateTable)
clearFields()
setEditRetentionModal(!editRetentionModal)
})
.catch((error) => {
// console.log(error)
alert(error.response.data.message)

})
}

const HandleDeletePopUp = async (id) => {
setCapacityUtilizationToDeleteId(id)
SetDeletePopUp(!deletePopUp)
}


const HandleDeleteRetention = async (id) => {
await axios.delete(`${Constants.API_PATH}/retention/${id}`)
.then((res) => {
console.log(res)
setUpdateTable(!UpdateTable)
SetDeletePopUp(!deletePopUp)

})
.catch((error) => {
console.log(error)
})

}

// operation on retention modal end

//operation on date start
const date = new Date()

const current_year = date.getFullYear()
const prev_year = current_year - 1
const next_year = current_year + 1
const two_prev_year = current_year - 2
//operation on date end


return (
    <section id="formPages" className="formPages">
<div className="col-md-12 pad text-center">
<div>
{/* <Sidebar> */}
<div>
<div className='bg-black w-full h-[70px]'></div>
<div className='flex justify-between items-center bg-[#1c2839] px-[20px] py-[15px]'>
<div><h4 className='text-[30px] text-white'>Retention</h4></div>
</div>

{
showPage && showPage ? (
<div className='px-[10px]'>
<div className='flex justify-between my-[10px]'>
<div className='bg-[#1c1d1f] px-[15px] py-[5px] rounded-md'>
</div>
<div className='bg-[#1c1d1f] px-[15px] py-[5px] rounded-md' onClick={() => setShowModal(true)}><button className='text-[#e4dbdb]'>Add Retention</button></div>

</div>


<div className='w-full '>
<table className='w-full' >
<thead>
<tr className=' border-[1px] solid'>
<th>#</th>
<th>Schools</th>
<th>Total Strength {`${two_prev_year}-${prev_year}`}</th>
<th>New Admission {`${prev_year}-${current_year}`}</th>
<th>Ideal Strength suppose till date</th>
<th>Total Dropouts</th>
<th>Net Strength till date</th>
<th>Retention %</th>
<th>Edit</th>
<th className='text-start'>Delete</th>
</tr>
</thead>
<tbody className='w-full'>
{
tableData && tableData.filter((data) => data.total_strength_session === `${two_prev_year}-${prev_year}` && data.new_admission_session === `${prev_year}-${current_year}`).map((data, index) => {
return (
<tr className='text-center border-[1px] solid' key={index}>
<td>{index + 1}</td>
<td>{data.shool_location}</td>
<td>{data.total_strength}</td>
<td>{data.new_admission}</td>
<td>{data.ideal_strength_till_date}</td>
<td>{data.total_dropouts}</td>
<td>{data.net_strength_till_date}</td>
<td>{data.retention_percent}</td>
<td className='flex justify-center'><button onClick={() => HandleEditRetentionById(data._id)} /></td>
<td ><button onClick={() => HandleDeletePopUp(data._id)} /></td>
</tr>
)

})
}

{/* <tr className='text-center border-[1px] solid'>
<td>2</td>
<td>Jacob</td>
<td className='flex justify-center'><FaEdit /></td>
<td ><MdDelete /></td>
</tr> */}

</tbody>
</table>

</div>


</div>
) : (<span>hii</span>)
}
</div>
{/* show modal start */}
<div>
{showModal && showModal ? (
<>
<div className='inset-0 flex justify-center items-center fixed z-[50] bg-[#1c1d1f]/30' >
<div className='w-[400px]'>
<div className='bg-[#1c2839] py-[10px] px-[10px] flex justify-between'><h5 className='text-white text-[25px]'>Add Retention</h5> <button onClick={() => setShowModal(!showModal)}><button size={30} color='white' /></button></div>
{/* input field block start */}
<form onSubmit={submitRateOfConversion} >
<div className='p-[10px] bg-white'>
<div className='p-[5px]'>
<select value={schoolLocation.toString()} onChange={(e) => setSchoolLocation(e.target.value)} className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select School Location..</option>
{
schoolLocationData && schoolLocationData.map((item, index) => {
return (
<option key={index} value={item.schoolLocation}>{item.schoolLocation}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={totalStrength.toString()} onChange={(e) => setSetTotalStrength(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="number" placeholder='Total Strength' />
<select value={totalStrengthSession.toString()} onChange={(e) => setSetTotalStrengthSession(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select Total Strength session..</option>
{
sessionData && sessionData.map((item, index) => {
return (
<option key={index} value={item.AddSession}>{item.AddSession}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={newAdmission.toString()} onChange={(e) => setNewAdmission(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="number" placeholder='New Admission' />
<select value={newAdmissionSession.toString()} onChange={(e) => setNewAdmissionSession(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select New Admisison session..</option>
{
sessionData && sessionData.map((item, index) => {
return (
<option key={index} value={item.AddSession}>{item.AddSession}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={totalDropouts.toString()} onChange={(e) => setTotalDropouts(e.target.value)} className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='Total dropouts' />
</div>
<hr className='h-[3px] bg-black' />
<div className='p-[5px] flex justify-between'>
<input value={idealStrengthTillDate.toString()} readOnly className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white' type="number" placeholder='Ideal strength suppose till date' />
</div>
<div className='p-[5px] flex justify-between'>
<input value={newStrengthTillDate.toString()} readOnly className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="number" placeholder='New strength till date' />
<input value={retentionPercent.toString()} readOnly className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="number" placeholder='Retention %' />
</div>

{/* <div className='p-[5px] flex justify-between'>
</div> */}
<div className='flex justify-center my-[10px]'>
<button type="submit" className='bg-[#1a5800] px-[15px] py-[5px] text-white rounded-md' >Submit</button>
</div>
</div>
</form>
{/* input field block end */}
</div>
</div>
</>
) : null}
</div>
{/* show modal end */}

{/* edit modal start */}
<div>
{
editRetentionModal && editRetentionModal ? (
<div className='inset-0 flex justify-center items-center fixed z-[50] bg-[#1c1d1f]/30' >
<div className='w-[400px]'>
<div className='bg-[#1c392f] py-[10px] px-[10px] flex justify-between'><h5 className='text-white text-[25px]'>Add Retention</h5> <button onClick={() => setEditRetentionModal(!editRetentionModal)}><button size={30} color='white' /></button></div>
{/* input field block start */}
<div className='p-[10px] bg-white'>
<div className='p-[5px]'>
<select value={editschoolLocation} onChange={(e) => setEditSchoolLocation(e.target.value)} className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select School Location..</option>
{
schoolLocationData && schoolLocationData.map((item, index) => {
return (
<option key={index} value={item.schoolLocation}>{item.schoolLocation}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={edittotalStrength} onChange={(e) => setEditTotalStrength(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='Total Strength' />
<select value={edittotalStrengthSession} onChange={(e) => setEditTotalStrengthSession(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select Total Strength session..</option>
{
sessionData && sessionData.map((item, index) => {
return (
<option key={index} value={item.AddSession}>{item.AddSession}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={editnewAdmission} onChange={(e) => setEditNewAdmission(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='New Admission' />
<select value={editnewAdmissionSession} onChange={(e) => setEditNewAdmissionSession(e.target.value)} className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white'>
<option value="">Select New Admisison session..</option>
{
sessionData && sessionData.map((item, index) => {
return (
<option key={index} value={item.AddSession}>{item.AddSession}</option>
)
})
}
</select>
</div>
<div className='p-[5px] flex justify-between'>
<input value={edittotalDropouts} onChange={(e) => setEditTotalDropouts(e.target.value)} className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='Total dropouts' />
</div>
<hr className='h-[3px] bg-black' />
<div className='p-[5px] flex justify-between'>
<input value={caleditidealStrengthTillDate} readOnly className='w-full p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='Ideal strength suppose till date' />
</div>

<div className='p-[5px] flex justify-between'>
<input value={caleditnewStrengthTillDate} readOnly className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='New strength till date' />
<input value={caleditretentionPercent} readOnly className='w-[48%] p-[8px] rounded-md outline-none bg-[#121212] text-white' type="text" placeholder='Retention %' />
</div>
<div className='flex justify-center my-[10px]'>
<button onClick={() => HandleEditRetention(RetentionById)} className='bg-[#1a5800] px-[15px] py-[5px] text-white rounded-md' >Update</button>
</div>
</div>
{/* input field block end */}
</div>
</div>
) : null
}
</div>
{/* edit modal end */}
{/* delete modal start */}
<div>
{
deletePopUp && deletePopUp ? (
<div className='inset-0 flex justify-center items-center fixed z-[50] bg-[#1c1d1f]/30' >
<div className='w-[300px] bg-white rounded-md py-[20px]'>
<div>
<h4 className='text-[20px] font-semibold text-center'>Are You sure?</h4>
</div>



<div className='flex gap-6 justify-center my-[10px]'>
{/* <button onClick={editCalculateUtilization} className='bg-[#1a5800] px-[15px] py-[5px] text-white rounded-md' >Calculate Total Utilization</button> */}
<button onClick={() => HandleDeleteRetention(CapacityUtilizationToDeleteId)} className='bg-[#ec4848] px-[15px] py-[5px] text-white rounded-md' >Delete</button>
<button onClick={() => SetDeletePopUp(!deletePopUp)} className='bg-[#1a5800] px-[15px] py-[5px] text-white rounded-md' >Cancel</button>
</div>
</div>
{/* input field block end */}
</div>
) : null
}
</div>
{/* delete modal end */}
 {/* </Sidebar> */}
</div>
</div>
</section>
)
}

export default Retention