import React from "react";

export const Footer = (props) => {
  return (
 
   <>
  {/* footer start */}
  <div className="i-anchor" id="footer"></div>
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <h2>Quick Link</h2>
          <ul className="footmenu">
            <li>
              <a href="https://sajk.jaipuria.edu.in/">School Website</a>
            </li>
            <li>
              <a href="#">Student / Parent Portal</a>
            </li>
            <li>
              <a href="https://sajk.jaipuria.edu.in/cambridge/">Cambridge</a>
            </li>
            <li>
              <a href="https://sajk.jaipuria.edu.in/admission/">Admission</a>
            </li>
            <li>
              <a href="#">Career</a>
            </li>
          </ul>
          <h2>Social Media</h2>
          <div className="demopadding">
            <div className="icon social fb">
             <a href="https://www.facebook.com/kanpurjaipuria/"><i className="fa fa-facebook" /></a> 
            </div>
            <div className="icon social fb">
              <i className="fa fa-instagram" />
            </div>
            <div className="icon social fb">
              <i className="fa fa-youtube" />
            </div>
            <div className="icon social tw">
              <i className="fa fa-twitter" />
            </div>
            <div className="icon social in">
              <i className="fa fa-linkedin" />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <h2>Discover</h2>
          <ul className="footmenu">
            <li>
              <a href="https://sajk.jaipuria.edu.in/comfest/">Comfest </a>
            </li>
            <li>
              <a href="https://sajk.jaipuria.edu.in/events/">News &amp; Events </a>
            </li>
            <li>
              <a href="">Group Magazine </a>
            </li>
            <li>
              <a href="">Alumni </a>
            </li>
            <li>
              <a href="">Success Stories </a>
            </li>
            <li>
              <a href=""> Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2>Seth Anandram Jaipuria School, Kanpur</h2>
          <div className="adress">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={29}
              viewBox="0 0 22 29"
              fill="none"
            >
              <g clipPath="url(#clip0_285_289)">
                <path
                  d="M12.2852 0C12.5912 0.0575055 12.8972 0.112711 13.2021 0.172516C14.751 0.474995 16.1665 1.09203 17.4208 2.05755C17.9046 2.43018 18.377 2.83099 18.7953 3.27609C19.7544 4.29566 20.4809 5.47107 20.9813 6.78909C21.5375 8.25491 21.7198 9.77535 21.6229 11.3274C21.5523 12.466 21.2594 13.5609 20.8348 14.6231C20.3653 15.7979 19.5925 16.7772 18.8893 17.8025C18.365 18.5668 17.8351 19.327 17.3085 20.089C15.9938 21.9907 14.6786 23.8924 13.3651 25.7946C12.6368 26.8499 11.9102 27.9062 11.1569 29C10.9044 28.6348 10.6616 28.2846 10.4194 27.935C9.35894 26.4036 8.295 24.8746 7.23905 23.3403C6.13522 21.7371 5.04394 20.1252 3.93784 18.5237C3.36854 17.699 2.74625 16.9101 2.20602 16.067C1.50338 14.971 1.06801 13.7616 0.820688 12.4735C0.644601 11.5563 0.615538 10.6351 0.676513 9.719C0.723812 9.0134 0.889072 8.31069 1.05547 7.61947C1.36662 6.32387 1.9946 5.17377 2.78158 4.11567C3.58338 3.03801 4.58178 2.17198 5.72264 1.47156C6.81564 0.800476 8.00323 0.385862 9.24895 0.124787C9.49855 0.0724569 9.75328 0.0408289 10.0057 0C10.7654 0 11.5256 0 12.2852 0ZM10.9546 5.2905C8.02888 5.31868 5.78305 7.61832 5.74487 10.4361C5.70441 13.4563 8.05851 15.684 10.8115 15.745C13.8643 15.8128 16.0759 13.4045 16.1528 10.6799C16.2389 7.63902 13.7908 5.29395 10.954 5.28993L10.9546 5.2905Z"
                  fill="#FF6B00"
                />
              </g>
              <defs>
                <clipPath id="clip0_285_289">
                  <rect
                    width={21}
                    height={29}
                    fill="white"
                    transform="translate(0.647461)"
                  />
                </clipPath>
              </defs>
            </svg>
            <strong>ADDRESS:</strong> <br />
            &nbsp; &nbsp; &nbsp; 70, Cantt, Kanpur – 208004
          </div>
          <div className="email">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={29}
              height={20}
              viewBox="0 0 29 20"
              fill="none"
            >
              <g clipPath="url(#clip0_289_291)">
                <path
                  d="M14.6475 13.7733L11.1875 10.8853L1.28748 18.9603C1.64748 19.2833 2.12748 19.4733 2.66748 19.4733H26.6275C27.1675 19.4733 27.6475 19.2833 28.0075 18.9603L18.1075 10.9043L14.6475 13.7923V13.7733Z"
                  fill="#FF6B00"
                />
                <path
                  d="M28.0075 0.986145C27.6475 0.663145 27.1675 0.473145 26.6275 0.473145H2.66748C2.12748 0.473145 1.64748 0.663145 1.28748 0.986145L14.6475 11.8731L28.0075 0.986145Z"
                  fill="#FF6B00"
                />
                <path
                  d="M0.647461 2.14502V17.934L10.3075 10.125L0.647461 2.14502Z"
                  fill="#FF6B00"
                />
                <path
                  d="M18.9875 10.125L28.6475 17.934V2.14502L18.9875 10.125Z"
                  fill="#FF6B00"
                />
              </g>
              <defs>
                <clipPath id="clip0_289_291">
                  <rect
                    width={28}
                    height={19}
                    fill="white"
                    transform="translate(0.647461 0.473145)"
                  />
                </clipPath>
              </defs>
            </svg>
            <strong>EMAIL: </strong> <br />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="mailto:kanpurjaipuria@gmail.com">
              kanpurjaipuria@gmail.com
            </a>
            ,
            <a href="mailto:kanpurjaipuria@gmail.com" className="break">
              {" "}
              kanpur@jaipuria.edu.in
            </a>
            <div className="phone">
              <a href="mailto:kanpurjaipuria@gmail.com" className="break">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={30}
                  viewBox="0 0 18 30"
                  fill="none"
                >
                  <g clipPath="url(#clip0_289_296)">
                    <path
                      d="M15.5393 0.143555H2.60321C1.52589 0.143555 0.647461 1.02184 0.647461 2.09898V27.1798C0.647461 28.257 1.52589 29.1436 2.60321 29.1436H15.531C16.6084 29.1436 17.4868 28.2653 17.4868 27.1881V2.09898C17.4868 1.02184 16.6084 0.143555 15.531 0.143555H15.5393ZM7.00365 1.55213H11.1389C11.2715 1.55213 11.3792 1.65984 11.3792 1.79241C11.3792 1.92498 11.2715 2.0327 11.1389 2.0327H7.00365C6.87105 2.0327 6.76332 1.92498 6.76332 1.79241C6.76332 1.65984 6.87105 1.55213 7.00365 1.55213ZM9.07541 28.1576C8.53675 28.1576 8.09754 27.7184 8.09754 27.1798C8.09754 26.6413 8.53675 26.2021 9.07541 26.2021C9.61407 26.2021 10.0533 26.6413 10.0533 27.1798C10.0533 27.7184 9.61407 28.1576 9.07541 28.1576ZM16.136 25.5144H2.01483V3.2507H16.136V25.5144Z"
                      fill="#FF6B00"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_289_296">
                      <rect
                        width="16.8476"
                        height={29}
                        fill="white"
                        transform="translate(0.647461 0.143555)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <strong>Phone:</strong> <br />
                &nbsp; &nbsp; &nbsp;{" "}
              </a>
              <a href="#">9810680591</a> | <a href="#">9311964775</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footbg">
      <div className="container ">
        <div className="row">
          <div className="col-md-12 pull-left">
            <div className="copywright">
              <a href="www.google.com">
              © Copyright 2019 Seth Anandram Jaipuria School. All rights reserved.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</>

 
  );
};
