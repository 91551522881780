import React from "react";
import { Link } from "react-router-dom";

export const Schedule = (props) => {
 
return (
<>
  {/* sec7 */}
  <div class="i-anchor" id="Schedule" tabindex="-1" ></div>
    <section id="Schedule0" className="sec7">
      <div className="container">
        <div className="row">
          <div className="utsav">
            <div>
              <p className="schedule">स्वर्णोत्सव</p>
              <p className="time">THE SCHEDULE</p>
            <div className="org-line" />
          </div>
        <div>
        <p className="month">SATURDAY, april 27, 2024 <br/>
        <span className="redColor">FORMAL PROGRAMmE</span>
        <br/> <span className="time-span newtime">5:00pm to 7:30pm | Venue : SCHOOL CAMPUS</span></p>
      </div>

        <div className="table-responsive">
          <div className="table1">
            <table className="tbody newtbl">
              <tbody>
                <tr className="left-border" id="td-width">
                  {/* <td className="tblwidth" rowSpan={13}> */}
                  {/* <span className="td-content formal"></span> */}
                    {/* <p /> */}
                    {/* <p className="pad-svg">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={30}
                          height={30}
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_371_189)">
                            <path
                              d="M11.0366 29.9976C8.08945 29.9976 5.31313 28.8425 3.22228 26.7445C1.13618 24.6537 -0.00708273 21.888 3.30173e-05 18.9564C0.00714877 16.0484 1.1599 13.3017 3.246 11.2227C5.33092 9.14492 8.08234 7.99929 10.9927 7.9981H10.9974C13.9647 7.9981 16.7481 9.14967 18.8366 11.2405C20.9108 13.3171 22.0494 16.0745 22.0446 19.0038C22.034 25.0652 17.0956 29.9976 11.0366 29.9976ZM10.9974 9.18406H10.9927C5.59894 9.18643 1.19904 13.5721 1.18599 18.9599C1.17888 21.575 2.19999 24.0417 4.06194 25.9084C5.92982 27.7811 8.40729 28.8129 11.0366 28.8129C16.4433 28.8129 20.8492 24.413 20.8587 19.0038C20.8634 16.3911 19.847 13.9315 17.9981 12.0802C16.1338 10.2135 13.648 9.18525 10.9986 9.18525L10.9974 9.18406ZM11.046 28.4013C8.51521 28.4013 6.14448 27.4288 4.36792 25.6606C2.59017 23.8911 1.60819 21.524 1.60345 18.9967C1.59396 13.8188 5.81597 9.59559 11.014 9.58254H11.0366C16.2168 9.58254 20.4436 13.7868 20.4649 18.9647C20.4756 21.4694 19.5055 23.8306 17.7349 25.6143C15.9583 27.4039 13.5935 28.393 11.0745 28.4013C11.065 28.4013 11.0543 28.4013 11.0437 28.4013H11.046ZM11.0377 10.7685H11.0176C6.47299 10.7792 2.78111 14.4699 2.78941 18.9943C2.79296 21.2061 3.6516 23.2744 5.20402 24.8197C6.75644 26.365 8.83068 27.2142 11.046 27.2154C11.0555 27.2154 11.0638 27.2154 11.0733 27.2154C13.2745 27.2083 15.3428 26.3437 16.8952 24.7794C18.4429 23.2211 19.2896 21.1575 19.2802 18.9706C19.2612 14.445 15.5657 10.7697 11.0377 10.7697V10.7685Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M21.1741 13.8567C20.8455 13.8567 20.4779 13.7453 20.2976 13.4571C19.2991 11.8643 17.9708 10.5764 16.3496 9.62998L15.9902 9.42006L16.065 9.01091C16.4018 7.16674 18.1273 5.77798 20.0806 5.77798H20.0842C22.06 5.78036 23.8081 7.23908 24.1496 9.1722C24.5042 11.1788 23.425 13.1214 21.5832 13.7927C21.4705 13.8342 21.327 13.8579 21.1741 13.8579V13.8567ZM17.3339 8.84013C18.8946 9.82566 20.1933 11.1112 21.2001 12.6684C22.4857 12.1857 23.2329 10.8064 22.9815 9.37737C22.7383 8.00284 21.4919 6.96513 20.083 6.96276H20.0806C18.8555 6.96276 17.7217 7.76091 17.3339 8.84013Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M20.1067 3.67884C20.0795 3.67884 20.0522 3.67766 20.0249 3.6741C19.6442 3.62666 19.0833 3.25309 19.0335 2.75261C18.9742 2.14777 18.9753 1.53226 19.0382 0.925049C19.0892 0.426947 19.649 0.0545559 20.0261 0.00711758C20.5313 -0.0569242 20.9879 0.463711 21.0828 0.849148C21.1516 1.12903 21.1385 1.39943 21.1267 1.63662C21.1255 1.66983 21.1231 1.70422 21.1219 1.73743V1.87263C21.1219 1.94379 21.1267 2.01613 21.1302 2.08847C21.1433 2.31855 21.1575 2.57827 21.0816 2.85223C20.9832 3.20802 20.5645 3.68003 20.1056 3.68003L20.1067 3.67884Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M27.6423 11.014C27.4916 11.014 27.3292 10.9998 27.1643 10.9452C26.8275 10.8349 26.327 10.4424 26.3223 9.95138C26.3187 9.48767 26.8002 8.94687 27.2604 8.89943C27.8427 8.83895 28.4404 8.83895 29.0358 8.89943C29.4615 8.94331 29.9905 9.41888 29.9988 9.91224C30.0071 10.3665 29.56 10.8349 29.1544 10.9488C28.8674 11.0306 28.591 11.0152 28.3479 11.0009C28.2684 10.9962 28.1902 10.9926 28.1131 10.9915C28.049 10.9915 27.9838 10.9962 27.9186 11.0009C27.8332 11.0069 27.7407 11.0128 27.6423 11.0128V11.014Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M15.8373 5.71869L15.2111 5.69734C15.1744 5.69616 15.1115 5.7009 15.051 5.70565C14.8091 5.72581 14.4782 5.7519 14.196 5.53131C13.7251 5.16366 13.3243 4.72011 13.0278 4.375C12.8001 4.11053 12.704 3.67647 12.7942 3.31713C12.8712 3.00996 13.1049 2.84749 13.2602 2.74075C13.2934 2.71822 13.3302 2.69213 13.348 2.67671L13.654 2.41461L14.011 2.60318C14.0548 2.6269 14.0975 2.64587 14.1367 2.66366C14.2564 2.71822 14.4047 2.78582 14.5387 2.91271C14.6016 2.97201 14.6656 3.0325 14.7296 3.09179C15.051 3.39184 15.3819 3.70256 15.6677 4.07021C15.8895 4.35484 15.8575 4.68809 15.835 4.9324C15.829 4.99288 15.8231 5.05455 15.8243 5.09251L15.8373 5.71869Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M25.2621 5.78036C25.013 5.78036 24.7628 5.70802 24.5885 5.58705C24.2967 5.38543 24.2232 5.0083 24.2042 4.8565C24.1639 4.51969 24.2742 4.31451 24.375 4.20185C24.7403 3.79032 25.1352 3.40844 25.5182 3.03961C25.6025 2.95896 25.6855 2.87832 25.7697 2.79649C25.9227 2.64824 26.0994 2.61622 26.1942 2.59843C26.2132 2.59487 26.2358 2.59132 26.2618 2.5842L26.5204 2.5166L26.7422 2.66485C26.7422 2.66485 26.7611 2.67315 26.7766 2.68145C26.9236 2.75498 27.1964 2.89136 27.2996 3.20564C27.3826 3.45825 27.4454 3.9777 27.1513 4.3157C26.735 4.79601 26.3045 5.22177 25.8729 5.58349C25.7104 5.71987 25.4874 5.77917 25.2621 5.77917V5.78036Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M26.3686 17.2925C26.1575 17.2925 25.95 17.2213 25.7804 17.0814C25.2254 16.626 24.7439 16.0994 24.3857 15.6926C24.0418 15.3001 24.2493 14.675 24.4367 14.4284C24.6194 14.1888 24.9526 14.0773 25.1827 14.056C25.4721 14.0275 25.6559 14.1319 25.7591 14.2244C26.1931 14.6134 26.6023 15.0308 26.9972 15.4341L27.2 15.6404C27.3684 15.8124 27.3909 16.0199 27.3992 16.1029L27.4099 16.1468V16.2156C27.4064 16.6354 27.1597 17.0221 26.7802 17.2C26.6473 17.2628 26.5062 17.2936 26.3675 17.2936L26.3686 17.2925Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M11.0223 27.4585H11.0045C8.73575 27.4537 6.61051 26.5714 5.01895 24.9739C3.4274 23.3764 2.55216 21.2464 2.55335 18.9765C2.55572 14.3252 6.36976 10.5396 11.0555 10.5361H11.0602C13.3135 10.5361 15.4364 11.4196 17.0363 13.0242C18.6373 14.63 19.5197 16.76 19.5209 19.0228C19.5209 21.2583 18.6421 23.3669 17.0446 24.962C15.4329 26.5714 13.2946 27.4573 11.0235 27.4573L11.0223 27.4585ZM11.0555 11.722C7.02322 11.7244 3.74168 14.9787 3.7393 18.9765C3.73693 22.991 6.99713 26.2642 11.0069 26.2713H11.0223C12.9779 26.2713 14.8173 25.5088 16.2061 24.1224C17.5794 22.7514 18.3349 20.9405 18.3337 19.0228C18.3337 17.0754 17.5735 15.2431 16.1954 13.8615C14.8197 12.481 12.9957 11.722 11.059 11.722H11.0543H11.0555ZM8.91364 22.5818C8.91364 22.5818 8.90771 22.5818 8.90415 22.5818C8.71677 22.5795 8.43807 22.5202 8.1736 22.2462C7.81663 21.8762 7.65771 21.2595 8.2839 20.6404C8.4274 20.4981 8.57208 20.3582 8.71677 20.217C9.14253 19.8031 9.54576 19.413 9.91577 18.9884C10.0379 18.8484 10.1352 18.6124 10.1411 18.4417C10.1743 17.5024 10.1672 16.5382 10.1613 15.6072C10.1601 15.357 10.1577 15.1067 10.1565 14.8565C10.1518 13.7595 10.8029 13.5804 11.1919 13.5709C11.5927 13.5614 11.8347 13.7192 11.9699 13.8532C12.3007 14.1793 12.296 14.6525 12.2936 14.879C12.2889 15.3641 12.2901 15.8586 12.2912 16.3366C12.2912 16.5394 12.2912 16.741 12.2912 16.9438C12.2912 17.0802 12.2865 17.2189 12.2818 17.3577C12.2723 17.6245 12.264 17.8759 12.296 18.1013C12.4502 19.1829 12.0837 20.0629 11.1753 20.7934C10.8408 21.0614 10.5372 21.3793 10.2158 21.7149C10.0735 21.8631 9.93119 22.0126 9.78532 22.1573C9.67977 22.2628 9.36075 22.5806 8.91245 22.5806L8.91364 22.5818Z"
                              fill="#FF6B00"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_371_189">
                              <rect width={30} height="29.9976" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="time-span newtime">5:00pm to 7:30pm</span>
                      </span>
                    </p> */}
                    {/* <br /> */}
                    {/* <p className="pad-svg">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={30}
                          height={30}
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.0625 12.6791C4.0625 6.55534 8.94377 1.5625 15 1.5625C21.0562 1.5625 25.9375 6.55534 25.9375 12.6791C25.9375 15.6355 25.095 18.8099 23.6056 21.5523C22.118 24.2914 19.9445 26.6715 17.2256 27.9424C15.8132 28.6025 14.1868 28.6025 12.7744 27.9424C10.0555 26.6715 7.88202 24.2914 6.39444 21.5523C4.90504 18.8099 4.0625 15.6355 4.0625 12.6791ZM15 3.4375C10.0105 3.4375 5.9375 7.55935 5.9375 12.6791C5.9375 15.3005 6.69079 18.1693 8.04213 20.6575C9.3953 23.1491 11.3075 25.187 13.5683 26.2438C14.4776 26.6687 15.5224 26.6687 16.4317 26.2438C18.6925 25.187 20.6047 23.1491 21.9579 20.6575C23.3092 18.1693 24.0625 15.3005 24.0625 12.6791C24.0625 7.55935 19.9895 3.4375 15 3.4375ZM15 9.6875C13.4467 9.6875 12.1875 10.9467 12.1875 12.5C12.1875 14.0533 13.4467 15.3125 15 15.3125C16.5533 15.3125 17.8125 14.0533 17.8125 12.5C17.8125 10.9467 16.5533 9.6875 15 9.6875ZM10.3125 12.5C10.3125 9.91117 12.4112 7.8125 15 7.8125C17.5888 7.8125 19.6875 9.91117 19.6875 12.5C19.6875 15.0888 17.5888 17.1875 15 17.1875C12.4112 17.1875 10.3125 15.0888 10.3125 12.5Z"
                            fill="#FF6B00"
                          />
                        </svg>
                        <span className="time-span">VENUE:</span>
                      </span>
                    </p>
                    <p className="td-content ganga">SCHOOL <br/> CAMPUS</p>
                    <p /> */}
                  {/* </td> */}
                  <td className="td-padding">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">05:00 - 05:45 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Exhibition - आनन्द प्रदर्शनी
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:00 - 06:05 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                 Lighting of the Lamp - आनन्द ज्योति
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:05 - 06:15 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  School Orchestra - आनन्द धारा
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:15 - 06:18 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">The Melody - आनन्द गीतिका</td>
                </tr>
                
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:18 - 06:28 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Jaipuria Movie - चित्रपट - एक स्वर्णिम यात्रा
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:28 - 06:38 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Speech By The Chairman, Jaipuria Group  – आनन्दवार्ता
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:38 - 06:43 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Speech by the Chairman, Governing Council, JOSA   – अभिव्यक्ति
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:43 - 06:51 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Dance Across India - आनद लास्य
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">06:51 - 07:04 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  FELICITATION CEREMONY - आनद अभिनंदन
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">07:04 - 07:09 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Unveiling the Postal Stamp – अनावरण
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">07:09 - 07:19 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Speech by the Chief Guest – आशीर्वचन
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">07:19 - 07:24 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Vote of Thanks – आभार
                  </td>
                </tr>   
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">07:24 - 07:28 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  School Anthem – आनंदज्ञान
                  </td>
                  </tr>
                  <tr>
                 
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">07:28 - 07:30 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  National Anthem – राष्ट्रगान
                  </td>
                  </tr>
              
            
                
               
              </tbody>
            </table>
          </div>
          </div>
          <br/>
        <p className="month">
        <span className="redColor">ENTERTAINMENT EVENING & DINNER</span>
        <br/> <span className="time-span newtime">8:00pm | Venue : KINGSTON RESORT, 85, The Mall, Cantonment, Kanpur</span><br/>
        <a href="https://maps.app.goo.gl/5qnTG5gco5cKkMnq5" target="_blank" > <button className="button nominate" style={{margin:"10px"}}>DIRECTION To The Venue</button></a> </p>
    

            <div className="table-responsive">
          <div className="table table-home">
            <table className="tbody">
              <tbody>
                <tr className="left-border" id="td-width">
                  {/* <td rowSpan={13} className="tblwidth"> */}
                   
                    {/* <p className="pad-svg">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={30}
                          height={30}
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_371_189)">
                            <path
                              d="M11.0366 29.9976C8.08945 29.9976 5.31313 28.8425 3.22228 26.7445C1.13618 24.6537 -0.00708273 21.888 3.30173e-05 18.9564C0.00714877 16.0484 1.1599 13.3017 3.246 11.2227C5.33092 9.14492 8.08234 7.99929 10.9927 7.9981H10.9974C13.9647 7.9981 16.7481 9.14967 18.8366 11.2405C20.9108 13.3171 22.0494 16.0745 22.0446 19.0038C22.034 25.0652 17.0956 29.9976 11.0366 29.9976ZM10.9974 9.18406H10.9927C5.59894 9.18643 1.19904 13.5721 1.18599 18.9599C1.17888 21.575 2.19999 24.0417 4.06194 25.9084C5.92982 27.7811 8.40729 28.8129 11.0366 28.8129C16.4433 28.8129 20.8492 24.413 20.8587 19.0038C20.8634 16.3911 19.847 13.9315 17.9981 12.0802C16.1338 10.2135 13.648 9.18525 10.9986 9.18525L10.9974 9.18406ZM11.046 28.4013C8.51521 28.4013 6.14448 27.4288 4.36792 25.6606C2.59017 23.8911 1.60819 21.524 1.60345 18.9967C1.59396 13.8188 5.81597 9.59559 11.014 9.58254H11.0366C16.2168 9.58254 20.4436 13.7868 20.4649 18.9647C20.4756 21.4694 19.5055 23.8306 17.7349 25.6143C15.9583 27.4039 13.5935 28.393 11.0745 28.4013C11.065 28.4013 11.0543 28.4013 11.0437 28.4013H11.046ZM11.0377 10.7685H11.0176C6.47299 10.7792 2.78111 14.4699 2.78941 18.9943C2.79296 21.2061 3.6516 23.2744 5.20402 24.8197C6.75644 26.365 8.83068 27.2142 11.046 27.2154C11.0555 27.2154 11.0638 27.2154 11.0733 27.2154C13.2745 27.2083 15.3428 26.3437 16.8952 24.7794C18.4429 23.2211 19.2896 21.1575 19.2802 18.9706C19.2612 14.445 15.5657 10.7697 11.0377 10.7697V10.7685Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M21.1741 13.8567C20.8455 13.8567 20.4779 13.7453 20.2976 13.4571C19.2991 11.8643 17.9708 10.5764 16.3496 9.62998L15.9902 9.42006L16.065 9.01091C16.4018 7.16674 18.1273 5.77798 20.0806 5.77798H20.0842C22.06 5.78036 23.8081 7.23908 24.1496 9.1722C24.5042 11.1788 23.425 13.1214 21.5832 13.7927C21.4705 13.8342 21.327 13.8579 21.1741 13.8579V13.8567ZM17.3339 8.84013C18.8946 9.82566 20.1933 11.1112 21.2001 12.6684C22.4857 12.1857 23.2329 10.8064 22.9815 9.37737C22.7383 8.00284 21.4919 6.96513 20.083 6.96276H20.0806C18.8555 6.96276 17.7217 7.76091 17.3339 8.84013Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M20.1067 3.67884C20.0795 3.67884 20.0522 3.67766 20.0249 3.6741C19.6442 3.62666 19.0833 3.25309 19.0335 2.75261C18.9742 2.14777 18.9753 1.53226 19.0382 0.925049C19.0892 0.426947 19.649 0.0545559 20.0261 0.00711758C20.5313 -0.0569242 20.9879 0.463711 21.0828 0.849148C21.1516 1.12903 21.1385 1.39943 21.1267 1.63662C21.1255 1.66983 21.1231 1.70422 21.1219 1.73743V1.87263C21.1219 1.94379 21.1267 2.01613 21.1302 2.08847C21.1433 2.31855 21.1575 2.57827 21.0816 2.85223C20.9832 3.20802 20.5645 3.68003 20.1056 3.68003L20.1067 3.67884Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M27.6423 11.014C27.4916 11.014 27.3292 10.9998 27.1643 10.9452C26.8275 10.8349 26.327 10.4424 26.3223 9.95138C26.3187 9.48767 26.8002 8.94687 27.2604 8.89943C27.8427 8.83895 28.4404 8.83895 29.0358 8.89943C29.4615 8.94331 29.9905 9.41888 29.9988 9.91224C30.0071 10.3665 29.56 10.8349 29.1544 10.9488C28.8674 11.0306 28.591 11.0152 28.3479 11.0009C28.2684 10.9962 28.1902 10.9926 28.1131 10.9915C28.049 10.9915 27.9838 10.9962 27.9186 11.0009C27.8332 11.0069 27.7407 11.0128 27.6423 11.0128V11.014Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M15.8373 5.71869L15.2111 5.69734C15.1744 5.69616 15.1115 5.7009 15.051 5.70565C14.8091 5.72581 14.4782 5.7519 14.196 5.53131C13.7251 5.16366 13.3243 4.72011 13.0278 4.375C12.8001 4.11053 12.704 3.67647 12.7942 3.31713C12.8712 3.00996 13.1049 2.84749 13.2602 2.74075C13.2934 2.71822 13.3302 2.69213 13.348 2.67671L13.654 2.41461L14.011 2.60318C14.0548 2.6269 14.0975 2.64587 14.1367 2.66366C14.2564 2.71822 14.4047 2.78582 14.5387 2.91271C14.6016 2.97201 14.6656 3.0325 14.7296 3.09179C15.051 3.39184 15.3819 3.70256 15.6677 4.07021C15.8895 4.35484 15.8575 4.68809 15.835 4.9324C15.829 4.99288 15.8231 5.05455 15.8243 5.09251L15.8373 5.71869Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M25.2621 5.78036C25.013 5.78036 24.7628 5.70802 24.5885 5.58705C24.2967 5.38543 24.2232 5.0083 24.2042 4.8565C24.1639 4.51969 24.2742 4.31451 24.375 4.20185C24.7403 3.79032 25.1352 3.40844 25.5182 3.03961C25.6025 2.95896 25.6855 2.87832 25.7697 2.79649C25.9227 2.64824 26.0994 2.61622 26.1942 2.59843C26.2132 2.59487 26.2358 2.59132 26.2618 2.5842L26.5204 2.5166L26.7422 2.66485C26.7422 2.66485 26.7611 2.67315 26.7766 2.68145C26.9236 2.75498 27.1964 2.89136 27.2996 3.20564C27.3826 3.45825 27.4454 3.9777 27.1513 4.3157C26.735 4.79601 26.3045 5.22177 25.8729 5.58349C25.7104 5.71987 25.4874 5.77917 25.2621 5.77917V5.78036Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M26.3686 17.2925C26.1575 17.2925 25.95 17.2213 25.7804 17.0814C25.2254 16.626 24.7439 16.0994 24.3857 15.6926C24.0418 15.3001 24.2493 14.675 24.4367 14.4284C24.6194 14.1888 24.9526 14.0773 25.1827 14.056C25.4721 14.0275 25.6559 14.1319 25.7591 14.2244C26.1931 14.6134 26.6023 15.0308 26.9972 15.4341L27.2 15.6404C27.3684 15.8124 27.3909 16.0199 27.3992 16.1029L27.4099 16.1468V16.2156C27.4064 16.6354 27.1597 17.0221 26.7802 17.2C26.6473 17.2628 26.5062 17.2936 26.3675 17.2936L26.3686 17.2925Z"
                              fill="#FF6B00"
                            />
                            <path
                              d="M11.0223 27.4585H11.0045C8.73575 27.4537 6.61051 26.5714 5.01895 24.9739C3.4274 23.3764 2.55216 21.2464 2.55335 18.9765C2.55572 14.3252 6.36976 10.5396 11.0555 10.5361H11.0602C13.3135 10.5361 15.4364 11.4196 17.0363 13.0242C18.6373 14.63 19.5197 16.76 19.5209 19.0228C19.5209 21.2583 18.6421 23.3669 17.0446 24.962C15.4329 26.5714 13.2946 27.4573 11.0235 27.4573L11.0223 27.4585ZM11.0555 11.722C7.02322 11.7244 3.74168 14.9787 3.7393 18.9765C3.73693 22.991 6.99713 26.2642 11.0069 26.2713H11.0223C12.9779 26.2713 14.8173 25.5088 16.2061 24.1224C17.5794 22.7514 18.3349 20.9405 18.3337 19.0228C18.3337 17.0754 17.5735 15.2431 16.1954 13.8615C14.8197 12.481 12.9957 11.722 11.059 11.722H11.0543H11.0555ZM8.91364 22.5818C8.91364 22.5818 8.90771 22.5818 8.90415 22.5818C8.71677 22.5795 8.43807 22.5202 8.1736 22.2462C7.81663 21.8762 7.65771 21.2595 8.2839 20.6404C8.4274 20.4981 8.57208 20.3582 8.71677 20.217C9.14253 19.8031 9.54576 19.413 9.91577 18.9884C10.0379 18.8484 10.1352 18.6124 10.1411 18.4417C10.1743 17.5024 10.1672 16.5382 10.1613 15.6072C10.1601 15.357 10.1577 15.1067 10.1565 14.8565C10.1518 13.7595 10.8029 13.5804 11.1919 13.5709C11.5927 13.5614 11.8347 13.7192 11.9699 13.8532C12.3007 14.1793 12.296 14.6525 12.2936 14.879C12.2889 15.3641 12.2901 15.8586 12.2912 16.3366C12.2912 16.5394 12.2912 16.741 12.2912 16.9438C12.2912 17.0802 12.2865 17.2189 12.2818 17.3577C12.2723 17.6245 12.264 17.8759 12.296 18.1013C12.4502 19.1829 12.0837 20.0629 11.1753 20.7934C10.8408 21.0614 10.5372 21.3793 10.2158 21.7149C10.0735 21.8631 9.93119 22.0126 9.78532 22.1573C9.67977 22.2628 9.36075 22.5806 8.91245 22.5806L8.91364 22.5818Z"
                              fill="#FF6B00"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_371_189">
                              <rect width={30} height="29.9976" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="time-span newtime"></span>
                      </span>
                    </p> */}
                    {/* <p className="pad-svg">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={30}
                          height={30}
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.0625 12.6791C4.0625 6.55534 8.94377 1.5625 15 1.5625C21.0562 1.5625 25.9375 6.55534 25.9375 12.6791C25.9375 15.6355 25.095 18.8099 23.6056 21.5523C22.118 24.2914 19.9445 26.6715 17.2256 27.9424C15.8132 28.6025 14.1868 28.6025 12.7744 27.9424C10.0555 26.6715 7.88202 24.2914 6.39444 21.5523C4.90504 18.8099 4.0625 15.6355 4.0625 12.6791ZM15 3.4375C10.0105 3.4375 5.9375 7.55935 5.9375 12.6791C5.9375 15.3005 6.69079 18.1693 8.04213 20.6575C9.3953 23.1491 11.3075 25.187 13.5683 26.2438C14.4776 26.6687 15.5224 26.6687 16.4317 26.2438C18.6925 25.187 20.6047 23.1491 21.9579 20.6575C23.3092 18.1693 24.0625 15.3005 24.0625 12.6791C24.0625 7.55935 19.9895 3.4375 15 3.4375ZM15 9.6875C13.4467 9.6875 12.1875 10.9467 12.1875 12.5C12.1875 14.0533 13.4467 15.3125 15 15.3125C16.5533 15.3125 17.8125 14.0533 17.8125 12.5C17.8125 10.9467 16.5533 9.6875 15 9.6875ZM10.3125 12.5C10.3125 9.91117 12.4112 7.8125 15 7.8125C17.5888 7.8125 19.6875 9.91117 19.6875 12.5C19.6875 15.0888 17.5888 17.1875 15 17.1875C12.4112 17.1875 10.3125 15.0888 10.3125 12.5Z"
                            fill="#FF6B00"
                          />
                        </svg>
                        <span className="time-span">VENUE:</span>
                      </span>
                    </p> */}
                    
                    {/* <p className="td-content ganga" style={{margin:"10px"}}>  </p> */}
                    {/* <p /> */}
                  {/* </td> */}
                  <td className="td-padding">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">08:00 - 08:20 pm</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  Welcome & Reminiscences by Alumni
                  </td>
                </tr>
               
                <tr>
                  <td>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={31}
                        height={30}
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_363_396)">
                          <path
                            d="M15.5693 0C12.6026 0 9.70253 0.879735 7.23579 2.52796C4.76905 4.17618 2.84646 6.51886 1.71115 9.25975C0.575835 12.0006 0.278785 15.0166 0.857563 17.9264C1.43634 20.8361 2.86495 23.5088 4.96274 25.6066C7.06053 27.7044 9.73327 29.133 12.643 29.7118C15.5527 30.2906 18.5687 29.9935 21.3096 28.8582C24.0505 27.7229 26.3932 25.8003 28.0414 23.3336C29.6896 20.8668 30.5693 17.9667 30.5693 15C30.565 11.0231 28.9833 7.21026 26.1712 4.39815C23.3591 1.58604 19.5463 0.00430135 15.5693 0V0ZM15.5693 27.5C13.0971 27.5 10.6803 26.7669 8.62471 25.3934C6.5691 24.0199 4.96694 22.0676 4.02085 19.7835C3.07475 17.4995 2.82721 14.9861 3.30953 12.5614C3.79184 10.1366 4.98235 7.90932 6.73051 6.16117C8.47866 4.41301 10.706 3.2225 13.1307 2.74018C15.5555 2.25787 18.0688 2.50541 20.3529 3.45151C22.637 4.3976 24.5892 5.99976 25.9627 8.05537C27.3362 10.111 28.0693 12.5277 28.0693 15C28.0657 18.3141 26.7476 21.4914 24.4042 23.8348C22.0607 26.1782 18.8834 27.4964 15.5693 27.5Z"
                            fill="#FF6B00"
                          />
                          <path
                            d="M15.5691 7.5C15.2376 7.5 14.9197 7.6317 14.6853 7.86612C14.4508 8.10054 14.3191 8.41848 14.3191 8.75V14.1562L10.1054 16.7962C9.8236 16.9723 9.62328 17.253 9.5485 17.5768C9.47372 17.9005 9.53061 18.2407 9.70665 18.5225C9.88268 18.8043 10.1635 19.0046 10.4872 19.0794C10.8109 19.1542 11.1511 19.0973 11.4329 18.9212L16.2329 15.9212C16.4143 15.8076 16.5634 15.6493 16.666 15.4615C16.7687 15.2737 16.8214 15.0627 16.8191 14.8487V8.75C16.8191 8.41848 16.6874 8.10054 16.453 7.86612C16.2186 7.6317 15.9007 7.5 15.5691 7.5Z"
                            fill="#FF6B00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_363_396">
                            <rect
                              width={30}
                              height={30}
                              fill="white"
                              transform="translate(0.569336)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="time-span">08:20 onwards</span>
                    </span>
                  </td>
                  <td className="td-content td-pad">
                  {/* Live Performance by Salman Ali, BOLLYWOOD Singer & Indian Idol Fame
                followed by Dinner and Drinks */}
                Musical Evening   
                followed by Dinner 
                  </td>
                </tr>
             
              </tbody>
            </table>
            <p className="month">
        <span className="redColor" style={{fontSize:"18px"}}>ENTRY ONLY FOR REGISTERED ALUmni AND INVITED GUESTS</span><br/><br/>
        <a href="https://alumni.jaipuria.edu.in/participation/" > <button className="button nominate">REGISTER NOW</button></a> 
        </p>
          
          </div>
          </div>  



        </div>
      </div>
    </div>
  </section>




  






</>


  );
};
