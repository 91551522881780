import React from "react";
import { Link } from "react-router-dom";

export const Thankyou = (props) => {
 

  return (

<>

 <section id="thankyou" className="thankyou">
    <div className="container">
      <div className="row">
        <div className="utsav">
          <div>
            <h1 className="time" style={{fontSize:"160px"}}>THANK YOU</h1>
            <h1 className="time" style={{fontSize:"87px"}}>FOR YOUR SUPPORT</h1>
            <p style={{color:"#4a353b",fontSize:"33px",textAlign: "center",fontStyle: "normal",fontWeight: "600",lineHeight:"normal"}}>OUR TEAM WILL CONTACT YOU SHORTLY</p>
           
            <div className="org-line" />
          </div>
          <br/>
          <div className="btnsedule-count">
            <button className="button schedulebtn " style={{color:"#ff6b00"}}><Link to="/">RETURN TO HOME PAGE</Link></button>
            </div>
            <br/>
        </div>
      </div>
    </div>
  </section>




  






</>


  );
};
