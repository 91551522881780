import React from "react";

export const YearlongCelebrations = (props) => {
  return (
   <>
   <>
  {/* sec2 */}
  <section className="sec2">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h2 className="wow animate fadeInUp one">
            Yearlong Celebrations – Book Your Calendar
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-12 wow animate fadeInLeft two">
          <img className="img-fluid icon1" src="images/icon1.png" alt="" />
          <p className="bookclass">
            INAUGURAL CEREMONY
            <br />
            <span>APRIL 27, 2024</span>
            <br />1 Day
          </p>
        </div>
        <div className="col-lg-3 col-md-12 wow animate fadeInLeft one">
          <img className="img-fluid icon1" src="images/icon2.png" alt="" />
          <p className="bookclass">
            JAIPURIA BUSINESS FAIR + COMFEST <br />
            <span>SEPTEMBER 2024 </span>
            <br />3 Days
          </p>
        </div>
        <div className="col-lg-3 col-md-12 wow animate fadeInRight one">
          <img className="img-fluid icon1" src="images/icon3.png" alt="" />
          <p className="bookclass">
            FOUNDERS’ DAY + ALUMNI MEET
            <br />
            <span>DECEMBER 2024</span>
            <br />2 Days
          </p>
        </div>
        <div className="col-lg-3 col-md-12 wow animate fadeInRight two">
          <img className="img-fluid icon1" src="images/icon4.png" alt="" />
          <p className="bookclass">
            SPORTS DAY + GRAND FINALE
            <br />
            <span>FEBRUARY 2025</span>
            <br />2 Days
          </p>
        </div>
      </div>
    </div>
  </section>
</>

   </>
  );
};
