
//import React, { useState } from 'react';
import React, { useEffect,  useState} from 'react';
import { Banner } from "../components/banner";
import { GloriousYears } from "../components/gloriousyears";
import { YearlongCelebrations } from "../components/yearlongcelebrations";
import { Invitation } from "../components/Invitation";
import { KeyHighlights } from "../components/keyhighlights";
import { OurJourney } from "../components/ourJourney";
import { Schedule } from "../components/schedule";
import { InvitingAlumni } from "../components/invitingalumni";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import * as Constants from '../constants/Constants';
import lodash from "lodash";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

function AdminHome(props){
  const [authenticated, setAuthenticated] = useState(false);
  const [form_datas, setFormDatas] = useState([])

  

const [paginatedformData, setpaginatedFormData] = useState([]);
const [currentPage, setcurrentPage] = useState(1);
const pageSize = 10;
const handleExportCSV = () => {
    const csvData = paginatedformData.map(data => ({
      Id: data.id,
      FirstName: data.fname,
      LastName: data.lname,
      Email: data.email,
      MobileNumber: data.mobile_number,
      Batch: data.batch,
      Amount: data.amount
    }));
  
    const headers = [
      { label: 'Id', key: 'Id' },
      { label: 'First Name', key: 'FirstName' },
      { label: 'Last Name', key: 'LastName' },
      { label: 'Email', key: 'Email' },
      { label: 'Mobile Number', key: 'MobileNumber' },
      { label: 'Batch', key: 'Batch' },
      { label: 'Amount', key: 'Amount' }
    ];
  
    // Return the CSVLink component conditionally
    if (paginatedformData.length > 0) {
      return (
        <CSVLink data={csvData} headers={headers} filename={'form_data.csv'}>
          Export to CSV
        </CSVLink>
      );
    } else {
      // You can provide alternative content or an empty fragment
      return null;
    }
  };

  let history = useHistory();   

  useEffect(()=>{
      fetchFormData() 
  },[])

  const fetchFormData = async () => {
      await axios.get(Constants.API_PATH+'/form-submit-data').then(({data})=>{
        setFormDatas(data)
          setpaginatedFormData(lodash(data).slice(0).take(pageSize).value());
      })
  }

  

  const pagination = (pageNo) =>{
    setcurrentPage(pageNo);
    const startIndex = (pageNo-1)*pageSize;
    const paginatedItems = lodash(form_datas).slice(startIndex).take(pageSize).value();
    setpaginatedFormData(paginatedItems);
  }
  const pageCount = form_datas ? Math.ceil(form_datas.length / pageSize) : 0;
  const pages = lodash.range(1, pageCount + 1);


  
  

    return ( <>
<section id="formPages" className="formPages">
    <div className="container">
   
        <div className="tabled">
        <h1>All Form Data</h1>
        <div className='row'>
        <div className="col-md-4">{handleExportCSV()}</div>
        <div className="col-md-4"></div> 
        <div className="col-md-4"><Link className="button nominate mb-3" to="/register-list" style={{padding:"5px"}}>Show Register Data</Link></div>
        </div>
         {/* <button className="btn btn-success" onClick={handleExportCSV}>
  Export to CSV
</button> */}

<br/>
          <div className='row'>
            <div className="col-md-12">

            
              <div className='card' style={{border: "2px solid #d9c4c4"}}>
           
              <div style={{borderBottom: "2px solid #274e76"}}></div>
                <div className="">
                <table className="table table-bordered mb-0 text-center table-responsive table-striped table-hover " style={{display: "inline-table"}}>
                <thead className="thead-color">
                                <tr>
                                    <th className="thead-padding">Id</th>
                                    <th className="thead-padding">First Name</th>
                                    <th className="thead-padding">Last Name</th>
                                    <th className="thead-padding">Email</th>
                                    <th className="thead-padding">Mobile Number</th>
                                    <th className="thead-padding">Batch</th>
                                    <th className="thead-padding">Amount</th>
                                    <th className="thead-padding">Form Type</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                          
                               {
                                paginatedformData.map((data,index)=>(
                                   <tr key ={data.id}>
                                        <td>{++index}</td>
                                        <td> {data.fname}</td> 
                                        <td> {data.lname}</td> 
                                        <td> {data.email}</td> 
                                        <td> {data.mobile_number}</td>
                                        <td> {data.batch}</td>
                                        <td> {data.amount}</td>
                                        <td> {data.type}</td>
                                     
                                       </tr>
                                   ))
                               }
                             
                            </tbody>
                        </table>
                </div>
              </div>
              <br/>
              <nav className="d-flex justify-content-center" aria-label="Page navigation example">
              <ul class="pagination">
                {pages.map(page => (
                <li className={page===currentPage?"page-item active":"page-item"}>
                  <p class="page-link" onClick={()=>pagination(page)}>{page}</p>
                  </li>
                  )
                )}
              </ul>
            </nav>

           
             
            </div>
          </div>
        </div>
        
    </div>
    </section>
  
    </>
   );

}

export default AdminHome;