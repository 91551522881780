import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";


import { Footer } from "./components/footer";
import { useHistory } from 'react-router-dom';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Home from "./pages/Home";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Schedule } from "./pages/schedule";
import { Contact } from "./pages/contact";
import {Thankyou} from "./pages/thankyou"

import {AccommodationFormPage} from "./pages/FormsPages/AccommodationFormPage";
import {LunchCawnporeFormPage} from "./pages/FormsPages/LunchCawnporeFormPage";
import {DinnerAndDrinkAtGOldenBashFormPage} from "./pages/FormsPages/DinnerAndDrinkAtGOldenBashFormPage";
import {SufiMusicDjFormPage} from "./pages/FormsPages/SufiMusicDjFormPage";
import {MusicalNightAndDinnerFormPage} from "./pages/FormsPages/MusicalNightAndDinnerFormPage";
import {HighTeaAndDinnerAtSchoolCampusFormPage} from "./pages/FormsPages/HighTeaAndDinnerAtSchoolCampusFormPage";
import {ExpressionOfInterest} from "./pages/FormsPages/ExpressionofInterest";
import {NominateYourselfRegister} from "./pages/FormsPages/NominateYourselfRegister";
import Login from "./pages/Login";
import AdminHome from './pages/AdminHome';
import RegisterList from './pages/RegisterList';
import Neeraj from './pages/FormsPages/Neeraj'
import CommingSoon from './pages/CommingSong'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("loginCred"));
  console.log(isAuthenticated); // Check if it's true

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const history = useHistory();
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const redirectToRoute = (route) => {
    history.push(route);
    scroll.animateScroll(0);
  };

  return (
    <div>
 <Router>
      {/* <Route path="/login" exact component={Login} /> */}
      
     
     
     
        {/* <Route path="/" exact component={Home} /> */}
      
        <ScrollToTop />
        <Switch>
        <Route path="/test" exact>
      <Header data={landingPageData.Header} />
      <Home />
      <Footer data={landingPageData.Footer} /> 
      </Route>
        <Route path="/" exact>
      <Header data={landingPageData.Header} />
      <Home />
      <Footer data={landingPageData.Footer} /> 
      </Route>
      <Route path="/contact" exact >
      <Header data={landingPageData.Header} />
      <Contact/>

      </Route>
      <Route path="/schedule" exact >
      <Header data={landingPageData.Header} />
      <Schedule/>
      </Route>
      <Route path="/accommodation-at-ganga-valley" exact >
      <Header data={landingPageData.Header} />
      <AccommodationFormPage/>
      </Route>
      <Route path="/lunch-at-cawnpore-club" exact >
      <Header data={landingPageData.Header} />
      <LunchCawnporeFormPage/>
      </Route>
      <Route path="/dinner-and-drinks-at-jaipuria-golden-bash" exact >
      <Header data={landingPageData.Header} />
      <DinnerAndDrinkAtGOldenBashFormPage/>
      </Route>
      <Route path="/sufi-music-performance-and-dj" exact >
      <Header data={landingPageData.Header} />
      <SufiMusicDjFormPage/>
      </Route>
      <Route path="/musical-night-at-school" exact >
      <Header data={landingPageData.Header} />
      <MusicalNightAndDinnerFormPage/>
      </Route>
      <Route path="/high-tea-and-dinner-at-school-campus" exact >
      <Header data={landingPageData.Header} />
      <HighTeaAndDinnerAtSchoolCampusFormPage/>
      </Route>
      <Route path="/expression-of-interest" exact >
      <Header data={landingPageData.Header} />
      <ExpressionOfInterest/>
      </Route>
      <Route path="/thankyou" exact >
      <Header data={landingPageData.Header} />
      <Thankyou/>
      </Route>
    
          
         
     
  
        {/* <Header data={landingPageData.Header} />  */}
        {/* <Route path="/test" exact component={Home} />  */}
          <PrivateRoute path="/admin-home" exact component={AdminHome} />
          <PrivateRoute path="/register-list" exact component={RegisterList} />
          <Route path="/login" exact component={Login} />
        
         
          <Route path="/neeraj" exact component={Neeraj} />
        
          <Route path="/register" exact component={NominateYourselfRegister} />
        
        </Switch>
        {/* <Route path="/admin-home" exact component={AdminHome} /> */}
        {/* <Footer></Footer>   */}
        {/* <Contact data={landingPageData.Contact} /> */}
        {/* <Footer data={landingPageData.Footer} /> */}
        {/* <Route path="/sign-in"  exact component={SignIn} />
        <Route path="/reset-password"  exact component={ResetPassword} />
        <Redirect from="*" to="/sign-in" /> */}

     

</Router>
      {/* <Navigation />
     
      {/* <Header></Header> */}
    
      {/* <Contact data={landingPageData.Contact} /> */}
      
      

    </div>
  );
};

export default App;
