import React from "react";
import { Navbar, Nav } from "react-bootstrap";
export const Header = (props) => {
  return (
    <header className="header fixed">
        {/* <div className="news ">
          <p className="typo">
            <span className="ticker"> <strong>NEWS TICKER:</strong> </span> */}
           {/* <span className="Mumbai">MUMBAI CHAPTER LAUNCH JANUARY STAY TUNED</span> */}
           {/* </p>
           </div> */}
        <div className="container color">
          <div className="row"> 
            <div className="col-lg-5 col-md-3">
              <a className="navbar-brand" href="/"><img src="images/kanpurlogo.png" alt="Logo" className="logo" title="STTAR" /> </a>
            </div>
            <div className="col-lg-7 col-md-9">
            <Navbar expand="md" variant="dark" className="myheader fixed-top0">
              <Navbar.Toggle aria-controls="navbarsExampleDefault" />
              <Navbar.Collapse id="navbarsExampleDefault">
                <Nav className="mr-auto border0">
                  <Nav.Link href="#Schedule" title="SCHEDULE">
                    SCHEDULE
                  </Nav.Link>
                  <Nav.Link href="#Invitation" title="INVITATION" className="nav-link">
                    INVITATION
                  </Nav.Link>
                  <Nav.Link href="#KeyHighlights" title="HIGHLIGHTS" className="nav-link">
                    HIGHLIGHTS
                  </Nav.Link>
                  <Nav.Link href="#OurJourney" title="JOURNEY" className="nav-link">
                    JOURNEY
                  </Nav.Link>
                  {/* <Nav.Link href="/contact" title="SPONSORS" className="nav-link">
                    SPONSORS
                  </Nav.Link> */}
                  <Nav.Link href="#footer" title="CONTACT US" >
                    CONTACT US
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
            {/* <div className="col-lg-7 col-md-9">
            <nav className="navbar navbar-expand-md navbar-dark myheader fixed-top0">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul className="navbar-nav mr-auto border0">
        <li className="nav-item">
          <a className="nav-link" href="#Schedule" title="SCHEDULE">SCHEDULE</a>
        </li>
        <li className="nav-item">
          <a className="nav-link " href="#Invitation" title="INVITATION">INVITATION</a>
        </li>
        <li className="nav-item ">
          <a className="nav-link" href="#KeyHighlights" title="HIGHLIGHTS" >HIGHLIGHTS</a>
          {/* <div className="dropdown-menu" aria-labelledby="dropdown01">
<a className="dropdown-item" href="#">Action</a>
<a className="dropdown-item" href="#">Another action</a>
<a className="dropdown-item" href="#">Something else here</a>
</div> */}
        {/* </li>
        <li className="nav-item">
          <a className="nav-link" href="#OurJourney" title="JOURNEY">JOURNEY</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#top" title="SPONSORS">SPONSORS</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#top" title="CONTACT US">CONTACT US</a>
        </li>
      </ul> */}
      {/* <a href="#" class="reglink" data-toggle="modal" data-target="#largeModal"><button type="button" class="btn  reg-btn"> Register Now </button></a> */}
    {/* </div>
  </nav>
            </div> */} 
          </div>
        </div>
      </header>
  );
};
