import { Image } from "./image";
import React from "react";

export const Invitation = (props) => {
  return (
  
   <>
  {/* sec3*/}
  <div className="i-anchor" id="Invitation"></div>
  <section id="Invitation" className="sec3">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h2 className="wow animate fadeInUp one">
            Invitation to Golden Jubilee Celebration
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7 col-md-12  wow animate fadeInLeft three">
          <div className="sec1-count">
            <svg
              className="quateicon"
              xmlns="http://www.w3.org/2000/svg"
              width={70}
              height={60}
              viewBox="0 0 70 60"
              fill="none"
            >
              <g clipPath="url(#clip0_622_389)">
                <path
                  d="M29.6268 0.00355519C29.6963 0.00355519 29.7658 0.0035552 29.8389 0.00355521L30.8777 0.00533295L30.8777 11.255L30.1026 11.2816C23.5899 11.5 18.419 14.2201 14.7341 19.3691C12.685 22.233 11.5909 25.4485 11.4003 29.1788L30.8813 29.1788L30.8813 59.913L30.1293 59.9592C30.058 59.9645 29.9886 59.9698 29.9226 59.9734C29.7747 59.9858 29.6197 59.9964 29.4593 59.9964C20.0832 60 10.7071 60 1.33105 59.9982C1.16711 59.9982 1.01744 59.9822 0.885582 59.968C0.83569 59.9627 0.785799 59.9574 0.735907 59.9538L0.00178529 59.8917L0.0106954 28.0141C0.0641508 27.6501 0.114043 27.2861 0.162154 26.9221C0.269065 26.1303 0.379541 25.3135 0.530999 24.5057C1.77295 17.8706 4.95355 12.2209 9.98372 7.71284C15.4647 2.79999 21.9863 0.205966 29.3631 0.00533281C29.4487 0.00355899 29.536 0.00355518 29.6268 0.00355519ZM29.274 9.72095L29.274 1.60862C22.3409 1.82701 16.2113 4.28077 11.0546 8.90244C6.2935 13.1708 3.28218 18.5186 2.10615 24.8004C1.96182 25.5674 1.85491 26.3664 1.74978 27.137C1.70167 27.4868 1.65534 27.8383 1.60367 28.1881L1.60367 58.4003C10.8283 58.402 20.053 58.402 29.2776 58.4003L29.2776 30.775L9.75742 30.775L9.7699 29.9636C9.83939 25.6136 11.0689 21.7359 13.428 18.4405C17.2715 13.0713 22.5992 10.14 29.274 9.72095Z"
                  fill="#FF6B00"
                />
                <path
                  d="M68.8418 7.52814e-06C68.8899 7.53235e-06 68.9416 7.53686e-06 68.995 7.54154e-06L70 7.62939e-06L70 11.2639L69.2213 11.287C62.951 11.4716 57.8995 14.0159 54.2075 18.8507C51.8982 21.8744 50.6847 25.2656 50.5066 29.1948L69.9733 29.1948L69.9733 59.9609L39.2666 59.9609L39.174 59.2685C39.1686 59.2259 39.1615 59.185 39.1544 59.1442C39.1348 59.0235 39.1081 58.8743 39.1081 58.7074C39.1098 55.721 39.092 52.6813 39.076 49.7411C39.0368 42.9657 38.9958 35.9577 39.1918 29.067C39.4965 18.3375 44.4893 9.94111 54.0311 4.11387C58.3592 1.47191 63.2789 0.0887789 68.6547 0.00178135C68.7153 3.70238e-06 68.7759 7.52238e-06 68.8418 7.52814e-06ZM68.3963 9.72451L68.3963 1.60507C63.4214 1.72936 58.8724 3.03081 54.8685 5.4757C45.8167 11.0029 41.0824 18.9554 40.7937 29.1114C40.5995 35.9755 40.6405 42.9693 40.6797 49.7322C40.6957 52.5606 40.7135 55.4831 40.7135 58.363L68.3696 58.363L68.3696 30.7928L48.8744 30.7928L48.8815 29.9867C48.9243 25.4201 50.2856 21.347 52.9317 17.8848C56.7769 12.8494 61.9781 10.1062 68.3963 9.72451Z"
                  fill="#FF6B00"
                />
              </g>
              <defs>
                <clipPath id="clip0_622_389">
                  <rect
                    width={70}
                    height={60}
                    fill="white"
                    transform="translate(70 60) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
            <br />
            <p>
            I invite all the alumni, students, and teachers to make the Golden Jubilee Celebrations memorable with their participation and presence.
            </p>
            <span className="pa1">Shishir Jaipuria </span>
            <span className="pa2"> Chairman, </span>
            <span className="pa3">
              Seth Anandram Jaipuria Group of Educational Institutions
            </span>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 wow animate fadeInRight four">
        <img src="images/youtube.jpg" alt="Logo" className="uputube" title="STTAR" />
          {/* <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/hgCAweaBHUg"
            title="Shri Shishir Jaipuria - Chairman, Jaipuria Group sharing his thoughts on Skill Development"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          /> */}
        </div>
      </div>
    </div>
  </section>
</>

 
  );
};
