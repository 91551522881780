// import React from "react";

// export const GloriousYears = (props) => {
//   return (
//     <>
//     <section className="sec1">
//   <div className="container">
//     <div className="row">
//       <div className="col-lg-5 col-md-12 animate fadeInLeft three">
//         <img
//           className="img-fluid schoolpic"
//           src="images/schoolpic-1@2x.png"
//           alt=""
//         />
//       </div>
//       <div className="col-lg-7 col-md-12 animate fadeInRight four">
//         <div className="sec1-count">
//           <h2 className="animate fadeInUp one">
//             50 Glorious Years of Excellence
//           </h2>
//           <p className="animate fadeInUp one">
//             Seth Anandram Jaipuria School, Kanpur, is set to reach a historic
//             milestone with the 50th anniversary of its founding. To celebrate
//             this glorious journey of academic excellence and stellar
//             achievements, the school will organize yearlong events with active
//             engagements by students, teachers, parents, eminent personalities,
//             celebrities and community members.
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>

    
//     </>
//   );
// };

// import React, { useState } from "react";
// import { useInView } from "react-intersection-observer";

// export const GloriousYears = (props) => {
//   const [animationCount, setAnimationCount] = useState(0);
//   const [ref, inView] = useInView({
//     triggerOnce: true,
//     rootMargin: "-100px 0px", // Adjust this value based on your layout
//   });

//   const handleAnimationEnd = () => {
//     // Increase the animation count when the animation ends
//     setAnimationCount((prevCount) => prevCount + 1);
//   };

//   return (
//     <>
//       <section ref={ref} className="sec1">
//         <div className={`container ${inView ? "visible" : ""}`}>
//           <div className="row">
//             <div
//               className={`col-lg-5 col-md-12 animate fadeInLeft three ${
//                 animationCount % 2 === 0 ? "odd" : "even"
//               }`}
//               onAnimationEnd={handleAnimationEnd}
//             >
//               <img
//                 className="img-fluid schoolpic animate"
//                 src="images/schoolpic-1@2x.png"
//                 alt=""
//               />
//             </div>
//             <div className="col-lg-7 col-md-12 animate fadeInRight four">
//               <div className="sec1-count">
//                 <h2 className="animate fadeInUp one">
//                   50 Glorious Years of Excellence
//                 </h2>
//                 <p className="animate fadeInUp one">
//                   Seth Anandram Jaipuria School, Kanpur, is set to reach a
//                   historic milestone with the 50th anniversary of its founding.
//                   To celebrate this glorious journey of academic excellence and
//                   stellar achievements, the school will organize yearlong
//                   events with active engagements by students, teachers,
//                   parents, eminent personalities, celebrities, and community
//                   members.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };


import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
export const GloriousYears = (props) => {
  return (
    <>
      <section className="sec1">
        <div className="container">
          <div className="row">
          
            <div className="col-lg-5 col-md-12 animate three">
            <ScrollAnimation animateIn="fadeInLeft">
              <img
                className="img-fluid schoolpic"
                src="images/schoolpic-1@2x.png"
                alt=""
              />
              </ScrollAnimation>
            </div>
          
            <div className="col-lg-7 col-md-12 animate fadeInRight four">
              <div className="sec1-count">
              <ScrollAnimation animateIn="fadeInUp">
              <h2 className="animate one">
              50 Glorious Years of Excellence</h2>
                </ScrollAnimation>
                
                 
                <ScrollAnimation animateIn="fadeInUp">
                <p className="animate one">
                  Seth Anandram Jaipuria School, Kanpur, is set to reach a
                  historic milestone with the 50th anniversary of its founding.
                  To celebrate this glorious journey of academic excellence and
                  stellar achievements, the school will organize yearlong
                  events with active engagements by students, teachers, parents,
                  eminent personalities, celebrities and community members.
                </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

