import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
export const Banner = (props) => {

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateCountdown = () => {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
  
      let today = new Date();
      let countDownDate = new Date("04/27/2024 17:00:00"); 
  
      const distance = countDownDate - today; // Calculate the distance correctly
  
      const days = Math.max(Math.floor(distance / day), 0);
      const hours = Math.floor((distance % day) / hour);
      const minutes = Math.floor((distance % hour) / minute);
      const seconds = Math.floor((distance % minute) / second);
  
      setCountdown({ days, hours, minutes, seconds });
    };
  
    calculateCountdown(); // Initial call
  
    const intervalId = setInterval(calculateCountdown, 1000); // Update countdown every second
  
    return () => clearInterval(intervalId); // Cleanup function to clear interval
  }, []);
  
  

  return (

    <>
    <section id="Banners" className="banner">
    
       {/* banner start */}
      
       <div className="container">
         <div className="row">
           <div className="col-md-6">
             <div className="count-right">
               <div className>
                 <p className="tag wow animate fadeInUp one">GOLDEN JUBILEE CELEBRATION</p>
                 <div className="text-center">
                   <p className="session wow animate fadeInUp two">Inaugural CEREMONY - APRIL 27, 2024</p>
                   {/* <div class="line"></div> */}
                 </div>
               </div>
              <div className="container">
                 <div className="countdown" id="countdown">
                   <ul>
                     <li className="txt "><span className="num " id="days" >{countdown.days}</span><br />
                     day(s)</li>
                     <li className="txt "><span className="num " id="hours" >{countdown.hours}</span><br />
                       Hour(s)</li>
                     <li className="txt "><span className="num " id="minutes" >{countdown.minutes}</span><br />
                       Minute(s)</li>
                     <li className="txt "><span className="num " id="seconds" >{countdown.seconds}</span><br />
                       Second(s)</li>
                   </ul>
                 </div>
               </div> 
               {/*<div class="d-flex justify-content-evenly mb-3 bottom-box col-sm-12">

   <div class="p-2 bd-highlight box">
     <p class="num " >100</p>
     <p class="txt">DAYS</p>
   </div>
   <div class="p-2 bd-highlight box ">
      <p class="num">05</p>
     <p class="txt">HRS</p>
   </div>
   <div class="p-2 bd-highlight box">
      <p class="num">20</p>
     <p class="txt">MIN</p>
   </div>

   <div class="p-2 bd-highlight box ">
      <p class="num">39</p>
     <p class="txt">SEC</p>
   </div>
 </div>*/}
 <br/>
               <div className="d-flex justify-content-center mb-3 fav">
                 <div className="p-2 bd-highlight">
                   <a href="https://www.facebook.com/kanpurjaipuria/"><svg xmlns="http://www.w3.org/2000/svg" width={13} height={24} viewBox="0 0 13 24" fill="none">
                       <g clipPath="url(#clip0_231_503)">
                         <path d="M10.6061 0C11.019 0.0318359 11.4319 0.0610906 11.844 0.0946474C12.1 0.115298 12.3551 0.141971 12.6172 0.166063C12.6172 1.44638 12.6172 2.70175 12.6172 3.98638C12.512 3.98638 12.4129 3.98466 12.3146 3.98638C11.5009 3.99928 10.6854 3.97605 9.87514 4.03628C9.00365 4.10081 8.4899 4.55168 8.43473 5.42244C8.3606 6.60295 8.41663 7.79206 8.41663 9.00355C9.67861 9.00355 10.9587 9.00355 12.262 9.00355C12.0827 10.4387 11.9078 11.8456 11.7293 13.2704C10.6147 13.2704 9.51396 13.2704 8.38301 13.2704C8.38301 16.8558 8.38301 20.4215 8.38301 24C6.94087 24 5.5289 24 4.09625 24C4.09625 20.4292 4.09625 16.8687 4.09625 13.2859C2.92995 13.2859 1.78262 13.2859 0.617188 13.2859C0.617188 11.855 0.617188 10.4456 0.617188 9.01388C1.75848 9.01388 2.90495 9.01388 4.08849 9.01388C4.08849 8.90202 4.08763 8.81253 4.08849 8.72305C4.0997 7.46424 4.06522 6.20457 4.13245 4.9492C4.21176 3.47356 4.72034 2.15796 5.88405 1.1676C6.66589 0.503352 7.59169 0.176388 8.59765 0.0473237C8.67264 0.037859 8.74678 0.0163482 8.82091 0C9.41655 0 10.0113 0 10.6061 0Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_503">
                           <rect width={12} height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a>
                 </div>
                 <div className="p-2 bd-highlight">
                   <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fjaipuria-school-kanpur%2Fabout%2F"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                       <g clipPath="url(#clip0_231_499)">
                         <path d="M9.10213 24C9.10283 18.7669 9.10389 13.5337 9.10459 8.30026C9.10459 8.20053 9.10459 8.10079 9.10459 7.98661C10.6997 7.98661 12.2736 7.98661 13.8803 7.98661C13.8803 8.7059 13.8803 9.42484 13.8803 10.2136C13.9585 10.1156 13.9938 10.0796 14.0198 10.0377C14.884 8.63048 16.1742 7.88018 17.7767 7.64194C18.7592 7.49603 19.7407 7.56793 20.7057 7.8044C22.5101 8.24634 23.6153 9.41286 24.1474 11.1619C24.4012 11.9958 24.5164 12.8525 24.5731 13.7191C24.5816 13.8495 24.6027 13.9789 24.6179 14.1089C24.6179 17.4058 24.6179 20.7027 24.6179 23.9996C22.9615 23.9996 21.3055 23.9996 19.6491 23.9996C19.6477 21.2116 19.653 18.424 19.6382 15.6359C19.6353 15.1154 19.5821 14.5907 19.5007 14.0761C19.2995 12.8032 18.6101 12.0909 17.408 11.9792C15.7724 11.8277 14.5968 12.4793 14.2588 14.1988C14.1347 14.8296 14.0871 15.4841 14.0822 16.1279C14.0621 18.7517 14.072 21.3755 14.0709 23.9993C12.4146 24 10.7582 24 9.10213 24Z" fill="#5E5650" />
                         <path d="M0.992523 24C0.998867 23.8915 1.0105 23.7829 1.0105 23.6744C1.01155 18.5579 1.0112 13.4411 1.0112 8.32459C1.0112 8.2171 1.0112 8.10961 1.0112 7.98802C2.67499 7.98802 4.312 7.98802 5.9927 7.98802C5.9927 8.09058 5.9927 8.1956 5.9927 8.30062C5.9927 13.4252 5.9927 18.5494 5.9934 23.674C5.9934 23.7829 6.00292 23.8915 6.00821 24.0004C4.33631 24 2.66442 24 0.992523 24Z" fill="#5E5650" />
                         <path d="M3.41718 5.7882C1.77172 5.70855 0.541059 4.35348 0.620707 2.70873C0.696478 1.15066 2.06776 -0.0687187 3.66353 0.0031756C5.22159 0.0733078 6.4526 1.44846 6.37894 3.03754C6.30775 4.58467 4.93295 5.86115 3.41718 5.7882Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_499">
                           <rect width="24.0004" height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a>
                 </div>
                 <div className="p-2 bd-highlight">
                   <a href="https://www.instagram.com/jaipuriaschoolkanpur/"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                       <g clipPath="url(#clip0_231_495)">
                         <path d="M9.00235 0C11.3941 0 13.7863 0 16.178 0C16.7381 0.0310126 17.2987 0.0606156 17.8583 0.0935077C18.8385 0.151304 19.7985 0.320464 20.6979 0.725037C22.9054 1.71791 24.0684 3.48657 24.4302 5.83131C24.4941 6.24575 24.526 6.66489 24.573 7.08168C24.573 10.3023 24.573 13.5224 24.573 16.743C24.5589 16.8502 24.5439 16.9573 24.5307 17.0649C24.4668 17.5917 24.4415 18.1259 24.3339 18.6433C23.8574 20.9368 22.5619 22.5664 20.3567 23.4249C19.3004 23.836 18.1877 23.9483 17.067 23.9596C14.5273 23.9854 11.9871 24.0094 9.44733 23.9958C8.19978 23.9892 6.94565 23.984 5.71877 23.6861C3.73161 23.204 2.25569 22.0767 1.37794 20.216C0.838043 19.0713 0.673112 17.8393 0.659955 16.5894C0.632702 13.9951 0.603569 11.4 0.624244 8.8057C0.633171 7.71509 0.698956 6.61555 0.858248 5.5381C1.10635 3.85825 1.91597 2.46362 3.28992 1.43269C4.46746 0.549299 5.83107 0.197823 7.27316 0.0958572C7.84924 0.0549769 8.42579 0.0314825 9.00235 0ZM12.6214 21.8907C12.6219 21.8785 12.6219 21.8662 12.6224 21.8535C13.2553 21.8535 13.8892 21.8723 14.5217 21.8498C15.8792 21.8009 17.247 21.8169 18.5909 21.6487C20.4375 21.4175 21.6898 20.3579 22.1437 18.5202C22.327 17.7777 22.3904 16.9916 22.4036 16.2234C22.4449 13.8091 22.4543 11.3934 22.4393 8.97862C22.4332 8.00407 22.3866 7.02295 22.264 6.05686C22.0187 4.13078 20.8144 2.82403 18.9123 2.44483C18.0914 2.28084 17.2381 2.23197 16.3979 2.21787C14.2961 2.1831 12.1934 2.18921 10.0911 2.19626C8.88863 2.20002 7.6829 2.18498 6.49126 2.39455C4.67186 2.71454 3.42384 3.87 3.05168 5.68377C2.89145 6.46566 2.83836 7.27903 2.82144 8.07972C2.78056 10.0016 2.76505 11.9253 2.78667 13.8476C2.8017 15.2056 2.83789 16.5683 2.96757 17.9187C3.09444 19.2386 3.71705 20.3274 4.87438 21.0458C5.65674 21.5317 6.53637 21.7257 7.44279 21.7582C9.16822 21.8192 10.8951 21.8484 12.6214 21.8907Z" fill="#5E5650" />
                         <path d="M6.44099 12.0033C6.44615 8.59707 9.22366 5.82896 12.6219 5.84306C16.0384 5.85715 18.7976 8.62902 18.7765 12.0258C18.7553 15.4358 16.0037 18.1814 12.6106 18.1781C9.20534 18.1743 6.43582 15.4029 6.44099 12.0033ZM8.61422 12.0038C8.61093 14.216 10.388 15.9997 12.6003 16.0044C14.8153 16.0091 16.5981 14.2381 16.607 12.0244C16.6159 9.80186 14.8327 8.01159 12.6092 8.01159C10.3974 8.01206 8.61798 9.79012 8.61422 12.0038Z" fill="#5E5650" />
                         <path d="M19.0523 4.16227C19.8577 4.17683 20.4765 4.82622 20.4568 5.63725C20.4375 6.42854 19.791 7.04597 18.9936 7.03469C18.1999 7.02295 17.5684 6.35946 17.5848 5.55549C17.6013 4.76936 18.2558 4.14817 19.0523 4.16227Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_495">
                           <rect width="23.9558" height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a>
                 </div>
                 {/* <div className="p-2 bd-highlight">
                   <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                       <g clipPath="url(#clip0_231_493)">
                         <path d="M23.3232 0C22.0416 1.49272 20.7609 2.98621 19.4783 4.47841C17.9004 6.31399 16.3211 8.14853 14.7426 9.9836C14.697 10.0368 14.6531 10.0913 14.5927 10.164C17.7502 14.7595 20.9082 19.3558 24.0845 23.9784C23.9992 23.9859 23.9421 23.9953 23.8848 23.9953C21.6902 23.9961 19.4958 23.994 17.3012 24C17.1468 24.0005 17.0663 23.945 16.9831 23.8237C14.9486 20.8563 12.9099 17.8915 10.8717 14.9264C10.8198 14.8508 10.7653 14.7765 10.6999 14.6844C10.103 15.3774 9.52238 16.0512 8.9425 16.7252C6.90067 19.0989 4.85989 21.474 2.81259 23.843C2.74323 23.9233 2.60869 23.987 2.50309 23.9893C1.89506 24.0031 1.28676 23.9961 0.617188 23.9961C3.68944 20.4248 6.72989 16.8902 9.77816 13.3468C9.73331 13.2775 9.69394 13.2126 9.65092 13.1502C6.7033 8.85904 3.75515 4.56836 0.807786 0.277163C0.746773 0.188252 0.695408 0.0925614 0.63935 0C2.96694 0 5.2948 0 7.62265 0C7.64116 0.0341565 7.65655 0.0701381 7.67819 0.101687C9.6444 2.96405 11.6111 5.82615 13.5773 8.68878C13.6066 8.73128 13.6285 8.77899 13.6595 8.83427C16.2199 5.85849 18.7404 2.92937 21.261 0C21.9483 0 22.6356 0 23.3232 0ZM21.2433 22.4909C21.1695 22.3814 21.1231 22.3102 21.0743 22.2406C19.8089 20.4303 18.5436 18.6205 17.2782 16.8105C13.7815 11.809 10.2837 6.80835 6.79299 1.80273C6.66053 1.61265 6.52547 1.54617 6.29863 1.54982C5.43221 1.56338 4.56526 1.55503 3.69857 1.55581C3.6253 1.55581 3.55204 1.56364 3.45322 1.56911C3.50719 1.65307 3.54213 1.71226 3.5815 1.76831C8.37436 8.6249 13.1677 15.4812 17.9577 22.3396C18.0438 22.463 18.1327 22.5094 18.2826 22.5081C19.1884 22.501 20.0942 22.5047 20.9997 22.5042C21.0673 22.5042 21.1345 22.4971 21.2433 22.4909Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_493">
                           <rect width="23.4671" height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a>
                 </div> */}
                 <div className="p-2 bd-highlight">
                   <a href="https://www.youtube.com/channel/UCBcZFDmrp7vLZCqfGdHG1Cw"><svg xmlns="http://www.w3.org/2000/svg" width={35} height={24} viewBox="0 0 35 24" fill="none">
                       <g clipPath="url(#clip0_231_491)">
                         <path d="M17.6222 0C21.1316 0.136917 24.6415 0.261532 28.1492 0.419842C28.9763 0.45728 29.8049 0.567456 30.6203 0.713999C32.5984 1.06913 33.8708 2.41209 34.2456 4.5006C34.7599 7.36462 34.8631 10.2607 34.7658 13.1568C34.6979 15.1817 34.5033 17.2044 34.3066 19.2224C34.1628 20.7006 33.5634 21.9586 32.2605 22.7993C31.465 23.3127 30.5679 23.4614 29.6574 23.5577C26.1235 23.9305 22.5746 24.0155 19.0273 23.9973C15.4564 23.9791 11.8856 23.8716 8.31641 23.7561C7.16962 23.7192 6.02229 23.5716 4.88513 23.4053C2.93478 23.1197 1.60301 21.7799 1.19989 19.7679C0.797315 17.758 0.702684 15.7176 0.643875 13.6772C0.565818 10.9929 0.649221 8.31181 0.989248 5.64461C1.0657 5.04613 1.16728 4.44605 1.31751 3.86255C1.77837 2.0714 3.24113 0.864822 5.17864 0.622008C8.51796 0.202701 11.8765 0.167402 15.234 0.11071C16.029 0.0973392 16.824 0.108571 17.619 0.108571C17.6201 0.072737 17.6212 0.0363685 17.6222 0ZM14.3214 6.93836C14.3214 10.3693 14.3214 13.7435 14.3214 17.167C17.2886 15.4577 20.2195 13.7687 23.1979 12.0524C20.2195 10.3367 17.2945 8.65142 14.3214 6.93836Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_491">
                           <rect width="34.1818" height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a>
                 </div>
                 {/* <div className="p-2 bd-highlight">
                   <a href="https://api.whatsapp.com/send?phone=919810680591"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                       <g clipPath="url(#clip0_231_488)">
                         <path d="M0.617188 24C0.800847 23.3266 0.974741 22.6873 1.14939 22.0481C1.51145 20.7238 1.87764 19.4003 2.22956 18.0733C2.26336 17.9456 2.24834 17.7714 2.18561 17.6572C-1.04664 11.77 1.21924 4.3635 7.19173 1.31304C13.7929 -2.05855 21.8176 1.33181 23.9922 8.41152C25.9118 14.6608 22.3817 21.3537 16.1276 23.2706C13.0215 24.2227 10.0078 23.9245 7.10572 22.4639C6.99418 22.4075 6.8323 22.3918 6.71061 22.4233C4.76848 22.9228 2.82861 23.4336 0.888357 23.941C0.810987 23.9613 0.73174 23.9752 0.617188 24ZM3.46972 21.1873C4.66295 20.8744 5.80659 20.5788 6.94685 20.2709C7.14065 20.2187 7.28826 20.2446 7.45952 20.3471C9.66118 21.6676 12.0337 22.1026 14.5479 21.6027C19.8781 20.5424 23.3327 15.3849 22.3284 10.0337C21.3301 4.7173 16.1163 1.16656 10.7924 2.17762C4.13526 3.4422 0.666389 10.8667 4.01469 16.7532C4.36135 17.3627 4.43234 17.8638 4.216 18.506C3.9253 19.3695 3.72249 20.263 3.46972 21.1873Z" fill="#5E5650" />
                         <path d="M8.76993 6.42168C9.27283 6.29699 9.51996 6.60008 9.69611 7.05679C9.96577 7.755 10.2602 8.44419 10.5562 9.13188C10.6595 9.37112 10.6501 9.58182 10.4953 9.78801C10.2602 10.1009 10.0349 10.4216 9.78625 10.7236C9.61836 10.9272 9.60334 11.1093 9.7378 11.3354C10.7087 12.9666 12.0638 14.1466 13.8305 14.8505C14.0735 14.9474 14.2654 14.9031 14.4284 14.6995C14.7195 14.3352 15.0181 13.9761 15.3065 13.6099C15.5187 13.3406 15.6408 13.2738 15.9462 13.4127C16.7859 13.7958 17.6186 14.1958 18.4426 14.6112C18.5444 14.6627 18.6361 14.8505 18.6342 14.9737C18.615 16.2416 18.2421 16.8489 16.8919 17.4172C16.1257 17.7394 15.3302 17.6639 14.54 17.4435C11.6728 16.6446 9.56015 14.8422 7.87041 12.4655C7.34572 11.7275 6.87211 10.9662 6.6708 10.0712C6.38123 8.78296 6.69785 7.66636 7.64468 6.73417C7.95266 6.43107 8.32449 6.37098 8.76993 6.42168Z" fill="#5E5650" />
                       </g>
                       <defs>
                         <clipPath id="clip0_231_488">
                           <rect width="23.8982" height={24} fill="white" transform="translate(0.617188)" />
                         </clipPath>
                       </defs>
                     </svg></a> 
                 </div> */}
               </div>
                <a href="https://alumni.jaipuria.edu.in/participation/" > <button className="button nominate">REGISTER NOW</button></a> 
             </div>
           </div>
           <div className="col-md-6">
             <div className="count-right">
               <img className="img-fluid childhood" src="images/GJLogo.png" alt="" />
               <img className="img-fluid childhood" src="images/tamsoMa.png" alt="" />
             </div>
           </div>
         </div>
       </div>
    

    </section>
    </>
  );
};
