import React from "react";

export const KeyHighlights = (props) => {
  return (
 
   <>
  {/* sec4 */}
  <div className="i-anchor" id="KeyHighlights"></div>
  <section  className="sec4 spaceChairman">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h2 className="wow animate fadeInUp one ">
            Key Highlights of Golden Jubilee Celebration{" "}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-md-10 white-bg-count ">
          <div className="countnew">
            <div className="row">
              <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame1.svg"
                  alt=""
                />
                <p className="Highlights">
                  Eminent National Leader as Chief Guest
                </p>
              </div>
              {/* <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame2.svg"
                  alt=""
                />
                <p className="Highlights">
                  Famous Personalities as Guests of Honour
                </p>
              </div> */}
              {/* <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame3.svg"
                  alt=""
                />
                <p className="Highlights">Launch of Coffee Table Book</p>
              </div> */}
              <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame4.svg"
                  alt=""
                />
                <p className="Highlights">
                  Movie on “The 50 Years Journey of the School”
                </p>
              </div>
              <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame5.svg"
                  alt=""
                />
                <p className="Highlights">
                  Cultural programmes and performances by students
                </p>
              </div>
            </div>
            <div className="row">
             
              <div className="col-lg-6 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame6.svg"
                  alt=""
                />
                <p className="Highlights">The Virtual Hall of Fame</p>
              </div>
              {/* <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame7.svg"
                  alt=""
                />
                <p className="Highlights">Launch of Jaipuria Business Fair</p>
              </div> */}
              <div className="col-lg-6 col-md-12 wow animate fadeInUp one">
                {/* <img
                  className="img-fluid icon1"
                  src="images/Frame8.svg"
                  alt=""
                /> */}
                <img
                  className="img-fluid icon1"
                  src="images/Frame7.svg"
                  alt=""
                />
                <p className="Highlights">
                Entertainment Evening &amp; Dinner for Alumni
               
                </p>
              </div>
              {/* <div className="col-lg-4 col-md-12 wow animate fadeInUp one">
                <img
                  className="img-fluid icon1"
                  src="images/Frame8.svg"
                  alt=""
                />
                <img
                  className="img-fluid icon1"
                  src="images/singer 1.svg"
                  alt=""
                />
                <p className="Highlights">
                Musical Evening
               
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

   
  );
};
