import React from "react";

export const ChiefGuest = (props) => {
  return (
   <>
   <>
  {/* sec2 */}
  <section className="sec9">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h2 className="wow animate fadeInUp one respo">
            CHIEF GUEST AT INAUGURAL CEREMONY
          </h2>
         
           <h3 className="respo">SHRI SATISH MAHANA</h3>
           <h4 className="respo"> <br/>Speaker, Legislative Assembly,<br/> UTTAR PRADESh
          </h4>
         
        </div>
        <div className="col-lg-2 col-md-12"></div>
        {/* <div className="col-lg-4 col-md-12">
          <h2 className=" wow animate fadeInUp one space">
            LIVE PERFORMANCE
          </h2>
          <h3 className="h3new respo">SALMAN ALI</h3><br/>
          <h4 className="h4new respo1"> BOLLYWOOD Singer & INDIAN IDOL FAME</h4>
        </div> */}
      </div>
  
    </div>
  </section>
</>

   </>
  );
};
