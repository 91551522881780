import React from "react";

export const OurJourney = (props) => {
  return (
    <>
    {/* sec5 */}
    <div className="i-anchor" id="OurJourney"></div>
    <section className="sec5">
      <div className="container-boader">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h2 className="wow animate fadeInUp one">Glimpses of our Journey </h2>
          </div>
        </div>

        <div className="boader-top1"></div>
        <div className="row">
          <div className="owl-carousel owl-theme  negative-banner">
            <div className="item">
              <img src="images/slide1.png" alt="" />
            </div>
            <div className="item">
              <img src="images/slide2.png" alt="" />
            </div>
            <div className="item">
              <img src="images/slide3.png" alt="" />
            </div>
            <div className="item">
              <img src="images/slide1.png" alt="" />
            </div>
            <div className="item">
              <img src="images/slide2.png" alt="" />
            </div>
            <div className="item">
              <img src="images/slide3.png" alt="" />
            </div>
          </div>
        </div>
        <div className="boader-top"></div>

      </div>
    </section>
  </>
  
  );
};
