import React from 'react';

class ComingSoonPage extends React.Component {
  render() {
    return (
        <section id="Schedule" className="sdpage" >
      
      <div className="coming-soon-container" ><br/><br/><br/>
       <div class="wrapper">

<h1 class="title" style={{textAlign:"center"}}>
  <span style={{color: "#fd3267",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase"
,fontFamily: "'Allerta Stencil', sans-serif"}}>C</span>
  <span style={{color: "#66D9EF",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>o</span>
  <span style={{color: "#A6E22E",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>m</span>
  <span style={{color: "#FD971F",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>i</span>
  <span style={{color: "#9B59B6",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>n</span>
  <span style={{color: "#1ABC9C",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>g</span>
  
  <span style={{color: "#A6E22E",letterSpacing: "8px" ,fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>s</span>
  <span style={{color: "#fd3267",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>o</span>
  <span style={{color: "#fd3267",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>o</span>
  <span style={{color: "#3498DB",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>n</span>
  <span style={{color: "#9B59B6",letterSpacing: "8px",fontSize: "5.5vw",textTransform:"uppercase",fontFamily: "'Allerta Stencil', sans-serif"}}>!</span>
</h1>

  <h5 class="title" style={{textAlign:"center"}}>We're working  
  hard to bring you 
  something amazing.
  Stay tuned!</h5>




</div>

</div>
      </section>
    );
  }
}

export default ComingSoonPage;
